"use strict";

const spinner = {
  namespaced: true,
  state: {
    spinner: {
      isVisible: false
    }
  },
  mutations: {
    setSpinnerVisible(state, data) {
      state.spinner.isVisible = data;
    }
  }
};

export default spinner;
