<template>
  <div class="material-textfield">
    <input
      class="material-textfield__input"
      :value="value"
      :type="type"
      :maxlength="maxlength"
      :placeholder="placeholder"
      :disabled="disabled"
      :readonly="readonly"
      :class="customClass"
      @input="$emit('input', $event.target.value)"
      @blur="validate"
    />
    <label class="material-textfield__label">{{ placeholder }}</label>
  </div>
</template>
<script>
export default {
  name: "InputField",
  props: {
    value: {
      type: [String, Number],
      default: null
    },
    type: {
      type: String,
      default: "text"
    },
    maxlength: {
      type: [String, Number]
    },
    placeholder: {
      type: String,
      default: null
    },
    readonly: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    customClass: {},
    case: {
      type: String
    }
  },
  methods: {
    validate() {
      this.$emit("validate", {case: this.case});
    }
  }
};
</script>

<style lang="scss" scoped>
.material-textfield {
  position: relative;
  caret-color: #54c7a2;
}
.material-textfield__label {
  position: absolute;
  font-size: 16px;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background-color: white;
  color: #bbb;
  padding: 0 0.3rem;
  margin: 0 0.5rem;
  transition: 0.1s ease-out;
  transform-origin: left top;
  pointer-events: none;
}
.material-textfield__input {
  width: 100%;
  height: 56px;
  outline: none;
  border: 1px solid #d8dae5;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 1rem 0.7rem;
  color: gray;
  padding: 0 16px;
  color: #2c2c2c;
  transition: 0.1s ease-out;
}
.material-textfield input:hover {
  border: 1px solid #54c7a2;
  //box-shadow: 0px 0px 0px 2px rgba(84, 199, 162, 0.56);
}
.material-textfield input:focus {
  border: 1px solid #54c7a2;
}
.material-textfield input[status="errors"] {
  border: 1px solid #d14343;
}
.material-textfield input:focus + label {
  color: #54c7a2;
  top: 0;
  transform: translateY(-50%) scale(0.9);
}
.material-textfield input[status="errors"] + label {
  color: #d14343;
  top: 0;
  transform: translateY(-50%) scale(0.9);
}
.material-textfield input:not(:placeholder-shown) + label {
  top: 0;
  transform: translateY(-50%) scale(0.9);
}
</style>
