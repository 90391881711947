"use strict";

import {LINKEDCOMPONENT_API} from "@/api/linked-component.js";

const linkedComponent = {
  namespaced: true,
  state: {
    linkedComponentListData: {},
    linkedComponentReadData: {},
    linkedComponentUpdateData: {},
    linkedComponentDeleteData: {},
    linkedComponentAddData: {}
  },
  mutations: {
    setLinkedComponentList(state, data) {
      state.linkedComponentListData = data;
    },
    setLinkedComponentRead(state, data) {
      state.linkedComponentReadData = data;
    },
    setLinkedComponentUpdate(state, data) {
      state.linkedComponentUpdateData = data;
    },
    setLinkedComponentDelete(state, data) {
      state.linkedComponentDeleteData = data;
    },
    setLinkedComponentAdd(state, data) {
      state.linkedComponentAddData = data;
    }
  },
  actions: {
    // 연동 컴포넌트 목록
    actLinkedComponentList({commit}, payload) {
      return LINKEDCOMPONENT_API.linkedComponentList(payload).then(response => {
        commit("setLinkedComponentList", response.data);
        return response;
      });
    },
    // 연동 컴포넌트 상세
    actLinkedComponentRead({commit}, payload) {
      return LINKEDCOMPONENT_API.linkedComponentRead(payload).then(response => {
        commit("setLinkedComponentRead", response.data);
        return response;
      });
    },
    // 연동 컴포넌트 수정
    actLinkedComponentUpdate({commit}, payload) {
      return LINKEDCOMPONENT_API.linkedComponentUpdate(payload).then(
        response => {
          commit("setLinkedComponentUpdate", response.data);
          return response;
        }
      );
    },
    // 연동 컴포넌트 삭제
    actLinkedComponentDelete({commit}, payload) {
      return LINKEDCOMPONENT_API.linkedComponentDelete(payload).then(
        response => {
          commit("setLinkedComponentDelete", response.data);
          return response;
        }
      );
    },
    // 연동 컴포넌트 관계형 등록
    actLinkedComponentAdd({commit}, payload) {
      return LINKEDCOMPONENT_API.linkedComponentAdd(payload).then(response => {
        commit("setLinkedComponentAdd", response.data);
        return response;
      });
    }
  },
  getters: {
    getLinkedComponentListData(state) {
      return state.linkedComponentListData;
    },
    getLinkedComponentReadData(state) {
      return state.linkedComponentReadData;
    },
    getLinkedComponentUpdateData(state) {
      return state.linkedComponentUpdateData;
    },
    getLinkedComponentDeleteData(state) {
      return state.linkedComponentDeleteData;
    },
    getLinkedComponentAddData(state) {
      return state.linkedComponentAddData;
    }
  }
};

export default linkedComponent;
