<template>
  <div class="DAV-dimmed" @click="passEmit"></div>
</template>

<script>
export default {
  name: "Dimmed",
  data() {
    return {};
  },
  methods: {
    passEmit() {
      this.$emit("passEmit");
    }
  }
};
</script>

<style lang="scss">
.DAV-dimmed {
  z-index: 10000001;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
}
</style>
