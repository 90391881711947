<template>
  <div class="spinner" :class="spinnerClass">
    <div class="spinner__loader">
      <span></span>
      <img :src="require('@/assets/images/common/bi_spinner_before.svg')" />
    </div>
  </div>
</template>

<script>
export default {
  name: "Spinner",
  props: ["propsOverlay", "propsPartial"],
  computed: {
    // class binding
    spinnerClass() {
      return {
        "spinner--overlay": this.propsOverlay,
        "spinner--partial": this.propsPartial
      };
    }
  }
};
</script>

<style lang="scss" scoped>
@mixin animation($animation) {
  -webkit-animation: $animation;
  animation: $animation;
}
.spinner {
  z-index: 10000005;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  &--overlay {
    background-color: #f7f9fc;
  }
  &--partial {
    position: absolute;
  }
  &__loader {
    position: relative;
    width: 64px;
    height: 64px;

    span {
      z-index: 2;
      position: absolute;
      bottom: 0;
      left: 0;
      display: block;
      width: 64px;
      height: 0%;
      background-image: url($path + "bi_spinner.svg");
      background-repeat: no-repeat;
      background-position: center bottom;
      background-size: cover;
      transition: 0s;
      @include animation(loading 3s linear infinite);
    }
    img {
      z-index: 1;
      position: relative;
      display: block;
      width: 100%;
      height: auto;
      opacity: 0.3;
    }
  }
}
@-webkit-keyframes loading {
  0% {
    width: 100%;
    height: 0%;
    opacity: 1;
  }
  95% {
    width: 100%;
    height: 100%;
    opacity: 1;
  }
  100% {
    width: 100%;
    height: 100%;
    opacity: 0;
  }
}
@keyframes loading {
  0% {
    width: 100%;
    height: 0%;
    opacity: 1;
  }
  95% {
    width: 100%;
    height: 100%;
    opacity: 1;
  }
  100% {
    width: 100%;
    height: 100%;
    opacity: 0;
  }
}
</style>
