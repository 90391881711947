"use strict";

import wrapper from "@/api/wrapper/axios-wrapper.js";
import {getAuthTokenHeader} from "@/utils/auth.js";

export const SOLUTION = {
  // 솔루션 목록
  solutionList() {
    return wrapper.get(
      `/v1/solution`,
      {},
      {
        headers: getAuthTokenHeader()
      }
    );
  },
  // 아이콘 목록
  iconList(payload) {
    return wrapper.get(
      `/v1/icon-group/${payload.id}`,
      {},
      {
        headers: getAuthTokenHeader()
      }
    );
  },
  // 상품 목록
  productList(payload) {
    return wrapper.get(`/external/mysoho/product`, payload, {
      headers: getAuthTokenHeader()
    });
  },
  // 상품 개수
  productCount(payload) {
    return wrapper.get(`/external/mysoho/product/count`, payload, {
      headers: getAuthTokenHeader()
    });
  },
  // 서체 목록
  fontList(payload) {
    return wrapper.get(`/v1/typeface`, payload, {
      headers: getAuthTokenHeader()
    });
  },
  // 회원 서체 목록
  userFontList(payload) {
    return wrapper.get(
      `/v1/user-theme/${payload.user_theme_id}/typefaces`,
      {},
      {
        headers: getAuthTokenHeader()
      }
    );
  },
  // 회원 서체 수정
  userFontUpdate(payload) {
    return wrapper.patch(
      `/v1/user-theme/${payload.user_theme_id}/typefaces`,
      payload,
      {
        headers: getAuthTokenHeader()
      }
    );
  },
  // 테마 폰트 불러오기
  themeFontLoad(payload) {
    let params = {};
    params.mode = payload.mode || "user";

    return wrapper.get(`/v1/theme/${payload.themeId}/load`, params, {
      headers: getAuthTokenHeader()
    });
  }
};

export default SOLUTION;
