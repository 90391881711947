"use strict";

const TokenKey = "authToken";

export function getAuthToken() {
  let getCookie = function (name) {
    var value = document.cookie.match("(^|;) ?" + name + "=([^;]*)(;|$)");
    return value ? unescape(value[2]) : null;
  };

  return getCookie(TokenKey);
}

export function setAuthToken(token) {
  document.cookie =
    TokenKey + "=" + token + "; expire=0; path=/; domain=.qpicki.com";
}

export function removeAuthToken() {
  document.cookie =
    TokenKey +
    "=; expire=Thu, 01 Jan 1999 00:00:00 GMT; path=/; domain=.qpicki.com";
}

export function getAuthTokenHeader(param) {
  if (param === "attach") {
    return {
      Authorization: "Bearer " + getAuthToken(),
      "Content-Type": "multipart/form-data"
    };
  } else if (param === "raw") {
    return {
      Authorization: "Bearer " + getAuthToken(),
      "Content-Type": "application/json"
    };
  } else {
    return {Authorization: "Bearer " + getAuthToken()};
  }
}
