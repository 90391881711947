"use strict";

import wrapper from "@/api/wrapper/axios-wrapper.js";
import {getAuthTokenHeader} from "@/utils/auth.js";

export const USER_API = {
  createAssociate(solution_id, solution_user_id, apikey) {
    this.create(null, 0, null, null).then(res => {
      wrapper.post("/v1/user/" + res.id + "/solution", {
        solutionId: solution_id,
        solutionUserId: solution_user_id,
        apikey: apikey
      });
    });
  },
  createRegular(email, name, password, advAgree) {
    return this.create(email, 1, name, password, advAgree);
  },
  gradeUpRegular(user_id, email, name, password) {
    return wrapper.patch(
      "/v1/user/" + user_id + "/grade-up",
      {
        email: email,
        name: name,
        password: password,
        passwordConfirmation: password
      },
      {headers: getAuthTokenHeader()}
    );
  },
  create(email, grade, name, password, advAgree) {
    return wrapper.post("/v1/user", {
      email: email,
      grade: grade,
      name: name,
      password: password,
      passwordConfirmation: password,
      advAgree: advAgree
    });
  },
  create_associate(solutionId, solutionUserId, apikey, clientKey) {
    let params = {
      solutionId: solutionId,
      solutionUserId: solutionUserId,
      apikey: apikey,
      clientKey: clientKey
    };
    return wrapper.post("/v1/user/associate", params, {});
  },
  update(user_id, email, grade, name, password) {
    let param = {};

    if (email) {
      param.email = email;
    }
    if (grade) {
      param.grade = grade;
    }
    if (name) {
      param.name = name;
    }
    if (password) {
      param.password = password;
      param.passwordConfirmation = password;
    }

    return wrapper.patch("/v1/user/" + user_id, param, {
      headers: getAuthTokenHeader()
    });
  },
  setAdvAgree(user_id, agree) {
    return wrapper.patch(
      "/v1/user/" + user_id + "/adv-agree",
      {agree: agree},
      {headers: getAuthTokenHeader()}
    );
  },
  getAdvAgree(user_id) {
    return wrapper.get(
      "/v1/user/" + user_id + "/adv-agree",
      {},
      {headers: getAuthTokenHeader()}
    );
  },
  certifyMailResend(email) {
    return wrapper.post(
      "/v1/user/email-verification",
      {email: email},
      {headers: getAuthTokenHeader()}
    );
  },
  certify(user_id, hash, expires, signature) {
    let params = {};
    params.expires = expires;
    params.hash = hash;
    params.signature = signature;

    return wrapper.get(
      "/v1/user/email-verification/user.register/" + user_id,
      params,
      {headers: getAuthTokenHeader()}
    );
  },
  resetPasswordSend(email) {
    return wrapper.post("/v1/user/password/reset-mail", {email: email}, {});
  },
  resetPasswordCheck(email, token) {
    return wrapper.get(
      "/v1/user/password/reset-mail",
      {email: email, token: token},
      {}
    );
  },
  resetPassword(email, token, password, passwordConfirmation) {
    return wrapper.patch(
      "/v1/user/password/reset-mail",
      {email: email, token: token, password, passwordConfirmation},
      {}
    );
  },
  changePassword(oldPassword, newPassword, passwordConfirmation) {
    return wrapper.patch(
      "/v1/user/password",
      {
        password: oldPassword,
        changePassword: newPassword,
        passwordConfirmation: passwordConfirmation
      },
      {headers: getAuthTokenHeader()}
    );
  },
  checkPassword(password) {
    return wrapper.post(
      "/v1/user/password",
      {password: password},
      {headers: getAuthTokenHeader()}
    );
  },
  withdrawal(user_id) {
    return wrapper.delete("/v1/user/" + user_id, {
      headers: getAuthTokenHeader()
    });
  },
  checkEmail(email) {
    let params = {};
    params.email = email;

    return wrapper.get("/v1/user/email-duplicate-check", params, {
      headers: getAuthTokenHeader()
    });
  },
  userLog(params) {
    return wrapper.post("/v1/user/activity-log", params, {
      headers: getAuthTokenHeader()
    });
  },
  userProgressStep(params) {
    return wrapper.get(`/v1/user/${params.userId}/progress-step`, params, {
      headers: getAuthTokenHeader()
    });
  }
};

export default USER_API;
