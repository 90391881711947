import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import commonUtils from "@/utils/common.js";
import {getAuthToken} from "@/utils/auth.js";
import {AUTH_API} from "@/api/accounts/auth.js";
import accountsRouter from "@/router/accounts.js";
import externalRouter from "@/router/external.js";

Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => {
    if (err.name !== "NavigationDuplicated") throw err;
  });
};

const requireAuth = () => (from, to, next) => {
  return AUTH_API.logged().then(response => {
    if (response.status == "200") {
      if (response.data.emailVerifiedAt === null && response.data.grade === 1) {
        return next(
          `/accounts/signup/certify/notice?email=${response.data.privacy.email}`
        );
      }
      // 파트너모드 진입시 파트너 회원여부 검증
      const mode = commonUtils.getQueryString("mode");
      if (mode === "partner" && !response.data.partner) {
        store.commit("ModuleAlert/setCallback", -1);
        store.commit("ModuleAlert/setAlert", {
          isVisible: true,
          message: "접근 권한이 없습니다."
        });
        return false;
      }
      return next();
    } else {
      let authToken = getAuthToken();
      if (!authToken) {
        store.commit("ModuleAlert/setCallback", "/accounts/login");
        store.commit("ModuleAlert/setAlert", {
          isVisible: true,
          message: `로그인이 필요한 서비스입니다.<br>로그인 후 이용해주세요.`
        });
      }
    }
  });

  //return getAuthToken() ? next() : next("/");
};

export const router = new VueRouter({
  mode: "history",
  routes: [
    {
      path: "/",
      redirect: "/introduction/main"
    },
    {
      name: "editor",
      path: "/editor/:themeId/:preview?",
      component: () => import("@/views/Editor.vue"),
      beforeEnter: requireAuth(),
      props: true
    },
    {
      name: "preview",
      path: "/preview/:themeId",
      component: () => import("@/views/Preview.vue")
    },
    {
      name: "admin",
      path: "/admin",
      component: () => import("@/views/Admin.vue"),
      beforeEnter: requireAuth(),
      children: [
        {
          name: "Dashboard",
          path: "dashboard",
          component: () => import("@/views/admin/Dashboard.vue")
        },
        {
          name: "Theme",
          path: "theme/:siteId",
          component: () => import("@/views/admin/Theme.vue")
        },
        {
          name: "Alarm",
          path: "alarm",
          component: () => import("@/views/admin/Alarm.vue")
        },
        {
          name: "solution",
          path: "solution",
          component: () => import("@/components/admin/UserSolution.vue")
        },
        {
          name: "site",
          path: "site/:siteId?",
          component: () => import("@/components/admin/UserSite.vue")
        },
        {
          name: "image",
          path: "image",
          component: () => import("@/components/admin/UserImage.vue")
        },
        {
          name: "exportHistory",
          path: "export-history",
          component: () => import("@/views/admin/ExportHistory.vue")
        }
      ]
    },
    {
      name: "introduction",
      path: "/introduction",
      redirect: "/introduction/main",
      component: () => import("@/views/Intro.vue"),
      children: [
        {
          name: "main",
          path: "main",
          component: () => import("@/views/introduction/index.vue")
        },
        {
          name: "terms",
          path: "terms",
          component: () => import("@/views/introduction/terms.vue")
        },
        {
          name: "intro-component-list",
          path: "component-list",
          component: () => import("@/views/introduction/component-list.vue")
        },
        {
          name: "intro-component-preview",
          path: "component-preview",
          component: () => import("@/views/introduction/component-preview.vue")
        },
        {
          name: "intro-theme",
          path: "theme",
          component: () => import("@/views/introduction/theme.vue")
        },
        {
          name: "intro-theme-list",
          path: "theme-list",
          component: () => import("@/views/introduction/theme-list.vue")
        },
        {
          name: "intro-preview",
          path: "preview",
          component: () => import("@/views/introduction/preview.vue")
        },
        {
          name: "function01",
          path: "function01",
          component: () => import("@/views/introduction/function01.vue")
        },
        {
          name: "function02",
          path: "function02",
          component: () => import("@/views/introduction/function02.vue")
        },
        {
          name: "function03",
          path: "function03",
          component: () => import("@/views/introduction/function03.vue")
        }
      ]
    },
    {
      name: "option",
      path: "/option",
      component: () => import("@/views/Option.vue"),
      beforeEnter: requireAuth(),
      props: true
    },
    // {
    //   name: "introduction",
    //   path: "/introduction",
    //   redirect: "/introduction/main",
    //   component: () => import("@/views/Introduction.vue"),
    //   children: [
    //     {
    //       name: "intro-main",
    //       path: "main",
    //       component: () => import("@/views/introduction/main.vue")
    //     },
    //     {
    //       name: "intro-function1",
    //       path: "function1",
    //       component: () => import("@/views/introduction/function1.vue")
    //     },
    //     {
    //       name: "intro-function2",
    //       path: "function2",
    //       component: () => import("@/views/introduction/function2.vue")
    //     },
    //     {
    //       name: "intro-function3",
    //       path: "function3",
    //       component: () => import("@/views/introduction/function3.vue")
    //     },
    //     {
    //       name: "intro-function4",
    //       path: "function4",
    //       component: () => import("@/views/introduction/function4.vue")
    //     },
    //     {
    //       name: "intro-function5",
    //       path: "function5",
    //       component: () => import("@/views/introduction/function5.vue")
    //     },
    //     {
    //       name: "intro-function6",
    //       path: "function6",
    //       component: () => import("@/views/introduction/function6.vue")
    //     },
    //     {
    //       name: "intro-theme",
    //       path: "theme",
    //       component: () => import("@/views/introduction/theme.vue")
    //     },
    //     {
    //       name: "intro-theme-list",
    //       path: "theme-list",
    //       component: () => import("@/views/introduction/theme-list.vue")
    //     },
    //     {
    //       name: "intro-preview",
    //       path: "preview",
    //       component: () => import("@/views/introduction/preview.vue")
    //     },
    //     {
    //       name: "terms",
    //       path: "terms",
    //       component: () => import("@/views/introduction/terms.vue")
    //     }
    //   ]
    // },
    ...accountsRouter,
    ...externalRouter
  ],
  scrollBehavior() {
    return {x: 0, y: 0, behavior: "smooth"};
  }
});
