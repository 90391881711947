"use strict";

// 연동 컴포넌트 여백
export function setMargin(componentEtc) {
  if (typeof componentEtc === "string") {
    componentEtc = JSON.parse(componentEtc);
  } else {
    componentEtc = {
      marginTop: componentEtc?.marginTop || 0,
      marginLeft: componentEtc?.marginLeft || 0,
      marginRight: componentEtc?.marginRight || 0,
      marginBottom: componentEtc?.marginBottom || 0
    };
  }
  return componentEtc;
}

// 연동 테마
export function setTheme(data) {
  let componentLayout = {};
  let componentContainer = {};
  let index = 1;

  for (let p in data.editablePages) {
    componentContainer[p] = new Map();
    for (let i in data.editablePages[p].layouts) {
      for (let j of data.linkedComponentGroups) {
        if (j.id === data.editablePages[p].layouts[i]) {
          let arr = [];
          for (let k of j.linkedComponents) {
            arr.push(index);
            k.etc = setMargin(k.etc);
            k.index = index;
            componentContainer[p].set(index, k);
            index++;
          }
          componentLayout[p] = {
            ...componentLayout[p],
            [i]: arr
          };
        }
      }
    }
  }

  return {
    componentLayout,
    componentContainer
  };
}
