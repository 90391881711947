<template>
  <div class="frmBlock__dropdownField">
    <div class="DAV-dropdown" :class="{'DAV-dropdown-small': type === 'small'}">
      <button class="DAV-dropdown__button" @click.stop="isVisible = true">
        <span>{{ selectValue }}</span>
      </button>
      <div class="DAV-dropdown__list" v-show="isVisible">
        <div class="DAV-dropdown__selected">
          {{ selectValue }}
        </div>
        <ul v-click-outside:toggleLayer>
          <li v-for="(option, index) in options" :key="index">
            <button
              type="button"
              :class="{'select-active': option.text === selectValue}"
              @click="
                $emit('updateTarget', option.value);
                isVisible = false;
              "
            >
              {{ option.text }}
            </button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Dropdown",
  props: {
    type: {
      type: String
    },
    value: {
      type: [String, Number]
    },
    options: {
      type: Array,
      default: () => {
        return [
          {
            text: "현재창",
            value: "_self"
          },
          {
            text: "새창",
            value: "_blank"
          }
        ];
      }
    }
  },
  computed: {
    selectValue() {
      const filter = this.options.filter(i => i.value === this.value);
      return filter[0]?.text;
    }
  },
  data() {
    return {
      isVisible: false
    };
  },
  methods: {
    // 레이어 Toggle
    toggleLayer(value) {
      this.isVisible = !value ? value : !this.isVisible;
    }
  }
};
</script>

<style lang="scss" scoped>
/* 반응형 - mobile */
@media screen and (max-width: 359px) {
  .frmBlock__dropdownField {
    width: 108px;
  }
}
</style>
