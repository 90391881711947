"use strict";

const admin = {
  namespaced: true,
  state: {
    deviceType: null,
    bizType: [
      "패션/의류",
      "화장품/잡화",
      "가구/인테리어/조명",
      "유아/아동",
      "식품"
    ],
    toolTip: false
  },
  mutations: {
    setDeviceType(state, data) {
      state.deviceType = data;
    },
    setBizType(state, data) {
      state.bizType = data;
    },
    setToolTip(state, data) {
      state.toolTip = data;
    }
  },
  getters: {
    getDeviceType(state) {
      return state.deviceType;
    },
    getBizType(state) {
      return state.bizType;
    },
    getToolTip(state) {
      return state.toolTip;
    }
  }
};

export default admin;
