"use strict";

import {ATTACH_API} from "@/api/attach.js";

const attach = {
  namespaced: true,
  state: {
    attachAddData: {},
    attachDeleteData: {},
    attachUpdateData: {},
    partnerImageListData: {},
    partnerImageAddData: {},
    partnerImageDeleteData: {},
    partnerImageUsageData: {},
    partnerImageReadData: {},
    componentImageListData: {},
    componentImageAddData: {},
    componentImageDeleteData: {},
    componentImageUsageData: {},
    componentImageReadData: {},
    unsplashImageListData: {},
    unsplashSearchImageListData: {},
    unsplashImageReadData: {},
    unsplashImageDownloadData: {}
  },
  mutations: {
    setAttachAdd(state, data) {
      state.attachAddData = data;
    },
    setAttachDelete(state, data) {
      state.attachDeleteData = data;
    },
    setAttachUpdate(state, data) {
      state.attachUpdateData = data;
    },
    setPartnerImageList(state, data) {
      state.partnerImageListData = data;
    },
    setPartnerImageAdd(state, data) {
      state.partnerImageAddData = data;
    },
    setPartnerImageDelete(state, data) {
      state.partnerImageDeleteData = data;
    },
    setPartnerImageUsage(state, data) {
      state.partnerImageUsageData = data;
    },
    setPartnerImageRead(state, data) {
      state.partnerImageReadData = data;
    },
    setComponentImageList(state, data) {
      state.componentImageListData = data;
    },
    setComponentImageAdd(state, data) {
      state.componentImageAddData = data;
    },
    setComponentImageDelete(state, data) {
      state.componentImageDeleteData = data;
    },
    setComponentImageUsage(state, data) {
      state.componentImageUsageData = data;
    },
    setComponentImageRead(state, data) {
      state.componentImageReadData = data;
    },
    setUnsplashImageList(state, data) {
      state.unsplashImageListData = data;
    },
    setUnsplashSearchImageList(state, data) {
      state.unsplashSearchImageListData = data;
    },
    setUnsplashImageRead(state, data) {
      state.unsplashImageReadData = data;
    },
    setUnsplashImageDownload(state, data) {
      state.unsplashImageDownloadData = data;
    }
  },
  actions: {
    // 첨부파일 임시 저장
    actAttachAdd({commit}, payload) {
      return ATTACH_API.attachAdd(payload).then(response => {
        commit("setAttachAdd", response.data);
        return response;
      });
    },
    // 첨부파일 삭제
    actAttachDelete({commit}, payload) {
      return ATTACH_API.attachDelete(payload).then(response => {
        commit("setAttachDelete", response.data);
        return response;
      });
    },
    // 첨부파일 수정
    actAttachUpdate({commit}, payload) {
      return ATTACH_API.attachUpdate(payload).then(response => {
        commit("setAttachUpdate", response.data);
        return response;
      });
    },
    // 파트너 이미지 목록
    actPartnerImageList({commit}, payload) {
      return ATTACH_API.partnerImageList(payload).then(response => {
        commit("setPartnerImageList", response.data);
        return response;
      });
    },
    // 파트너 이미지 업로드
    actPartnerImageAdd({commit}, payload) {
      return ATTACH_API.partnerImageAdd(payload).then(response => {
        commit("setPartnerImageAdd", response.data);
        return response;
      });
    },
    // 파트너 이미지 삭제
    actPartnerImageDelete({commit}, payload) {
      return ATTACH_API.partnerImageDelete(payload).then(response => {
        commit("setPartnerImageDelete", response.data);
        return response;
      });
    },
    // 파트너 이미지 사용량 조회
    actPartnerImageUsage({commit}, payload) {
      return ATTACH_API.partnerImageUsage(payload).then(response => {
        commit("setPartnerImageUsage", response.data);
        return response;
      });
    },
    // 파트너 이미지 상세
    actPartnerImageRead({commit}, payload) {
      return ATTACH_API.partnerImageRead(payload).then(response => {
        commit("setPartnerImageRead", response.data);
        return response;
      });
    },
    // 컴포넌트 이미지 목록
    actComponentImageList({commit}, payload) {
      return ATTACH_API.componentImageList(payload).then(response => {
        commit("setComponentImageList", response.data);
        return response;
      });
    },
    // 컴포넌트 이미지 업로드
    actComponentImageAdd({commit}, payload) {
      return ATTACH_API.componentImageAdd(payload).then(response => {
        commit("setComponentImageAdd", response.data);
        return response;
      });
    },
    // 컴포넌트 이미지 삭제
    actComponentImageDelete({commit}, payload) {
      return ATTACH_API.componentImageDelete(payload).then(response => {
        commit("setComponentImageDelete", response.data);
        return response;
      });
    },
    // 컴포넌트 이미지 사용량 조회
    actComponentImageUsage({commit}, payload) {
      return ATTACH_API.componentImageUsage(payload).then(response => {
        commit("setComponentImageUsage", response.data);
        return response;
      });
    },
    // 컴포넌트 이미지 상세
    actComponentImageRead({commit}, payload) {
      return ATTACH_API.componentImageRead(payload).then(response => {
        commit("setComponentImageRead", response.data);
        return response;
      });
    },
    // unsplash 이미지 목록
    actUnsplashImageList({commit}, payload) {
      return ATTACH_API.unsplashImageList(payload).then(response => {
        commit("setUnsplashImageList", response.data);
        return response;
      });
    },
    // unsplash 이미지 검색 목록
    actUnsplashSearchImageList({commit}, payload) {
      return ATTACH_API.unsplashSearchImageList(payload).then(response => {
        commit("setUnsplashSearchImageList", response.data);
        return response;
      });
    },
    // unsplash 이미지 상제
    actUnsplashImageRead({commit}, payload) {
      return ATTACH_API.unsplashImageRead(payload).then(response => {
        commit("setUnsplashImageRead", response.data);
        return response;
      });
    },
    // unsplash 이미지 다운
    actUnsplashImageDownload({commit}, payload) {
      return ATTACH_API.unsplashImageDownload(payload).then(response => {
        commit("setUnsplashImageDownload", response.data);
        return response;
      });
    }
  },
  getters: {
    getAttachAddData(state) {
      return state.attachAddData;
    },
    getAttachDeleteData(state) {
      return state.attachDeleteData;
    },
    getAttachUpdateData(state) {
      return state.attachUpdateData;
    },
    getPartnerImageListData(state) {
      return state.partnerImageListData;
    },
    getPartnerImageAddData(state) {
      return state.partnerImageAddData;
    },
    getPartnerImageDeleteData(state) {
      return state.partnerImageDeleteData;
    },
    getPartnerImageUsageData(state) {
      return state.partnerImageUsageData;
    },
    getPartnerImageReadData(state) {
      return state.partnerImageReadData;
    },
    getComponentImageListData(state) {
      return state.componentImageListData;
    },
    getComponentImageAddData(state) {
      return state.componentImageAddData;
    },
    getComponentImageDeleteData(state) {
      return state.componentImageDeleteData;
    },
    getComponentImageUsageData(state) {
      return state.componentImageUsageData;
    },
    getComponentImageReadData(state) {
      return state.componentImageReadData;
    },
    getUnsplashImageListData(state) {
      return state.unsplashImageListData;
    },
    getUnsplashSearchImageListData(state) {
      return state.unsplashSearchImageListData;
    },
    getUnsplashImageReadData(state) {
      return state.unsplashImageReadData;
    },
    getUnsplashImageDownloadData(state) {
      return state.unsplashImageDownloadData;
    }
  }
};

export default attach;
