export default {
  install(Vue) {
    // 통화 천단위 콤마
    Vue.filter("currency", val => {
      if (!val && val !== 0) {
        return "";
      }
      return String(val).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    });

    // date 포맷팅
    Vue.filter("date", val => {
      const date = new Date(val);
      if (!date && date !== 0) {
        return "";
      }
      const year = date.getFullYear();
      const month =
        date.getMonth() + 1 >= 10
          ? date.getMonth() + 1
          : "0" + (date.getMonth() + 1);
      const day = date.getDate() >= 10 ? date.getDate() : "0" + date.getDate();
      const hour =
        date.getHours() >= 10 ? date.getHours() : "0" + date.getHours();
      const minute =
        date.getMinutes() >= 10 ? date.getMinutes() : "0" + date.getMinutes();
      return year + "." + month + "." + day + " " + hour + ":" + minute;
    });

    // date 포맷팅
    Vue.filter("yyyymmdd", val => {
      const date = new Date(val);
      if (!date && date !== 0) {
        return "";
      }
      const year = date.getFullYear();
      const month =
        date.getMonth() + 1 >= 10
          ? date.getMonth() + 1
          : "0" + (date.getMonth() + 1);
      const day = date.getDate() >= 10 ? date.getDate() : "0" + date.getDate();

      return year + "." + month + "." + day;
    });

    // 알림 타입 별 연결 url
    Vue.filter("notification_code", val => {
      if (val === "user.join") {
        return `${process.env.VUE_APP_EXTERNAL_qpick_help_URL}/gallery?id=1`;
      } else if (val === "inquiry.answer") {
        return `${process.env.VUE_APP_EXTERNAL_qpick_help_URL}/inquiry`;
      } else if (val === "solution.link") {
        return `/admin/solution`;
      } else if (val === "solution.unlink") {
        return `/admin/solution`;
      } else {
        return "";
      }
    });
  }
};
