import axios from "axios";
import querystringify from "querystringify";
import store from "@/store";

const wrapper = axios.create({
  baseURL: process.env.VUE_APP_BASE_API
});

wrapper.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response.status < 500) return Promise.reject(error.response);
    else {
      // status 가 500 이상인 경우 서버오류로 간주하고 오류 메세지 출력
      store.commit("ModuleAlert/setAlert", {
        isVisible: true,
        message: `오류가 발생하였습니다.<br>잠시 후 다시 이용해 주세요.`
      });
    }
  }
);

export default {
  async get(path, payload, config) {
    try {
      store.commit("ModuleSpinner/setSpinnerVisible", true);
      const response = await wrapper.get(
        path + "?" + querystringify.stringify(payload, ""),
        config
      );
      store.commit("ModuleSpinner/setSpinnerVisible", false);
      return response;
    } catch (e) {
      store.commit("ModuleSpinner/setSpinnerVisible", false);
      return e;
    }
  },

  async post(path, payload, config) {
    store.commit("ModuleSpinner/setSpinnerVisible", true);
    try {
      const response = await wrapper.post(path, payload, config);
      store.commit("ModuleSpinner/setSpinnerVisible", false);
      return response;
    } catch (e) {
      store.commit("ModuleSpinner/setSpinnerVisible", false);
      return e;
    }
  },

  async patch(path, payload, config) {
    store.commit("ModuleSpinner/setSpinnerVisible", true);
    try {
      const response = await wrapper.patch(path, payload, config);
      store.commit("ModuleSpinner/setSpinnerVisible", false);
      return response;
    } catch (e) {
      store.commit("ModuleSpinner/setSpinnerVisible", false);
      return e;
    }
  },

  async delete(path, config) {
    store.commit("ModuleSpinner/setSpinnerVisible", true);
    try {
      const response = await wrapper.delete(path, config);
      store.commit("ModuleSpinner/setSpinnerVisible", false);
      return response;
    } catch (e) {
      store.commit("ModuleSpinner/setSpinnerVisible", false);
      return e;
    }
  }
};
