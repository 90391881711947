var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "spinner",
    class: _vm.spinnerClass
  }, [_c('div', {
    staticClass: "spinner__loader"
  }, [_c('span'), _c('img', {
    attrs: {
      "src": require('@/assets/images/common/bi_spinner_before.svg')
    }
  })])]);
}
var staticRenderFns = []

export { render, staticRenderFns }