"use strict";

import wrapper from "@/api/wrapper/axios-wrapper.js";
import {getAuthTokenHeader} from "@/utils/auth.js";

export const AUTH_API = {
  editablePageList(payload) {
    return wrapper.get(
      `/v1/theme/${payload.themeId}/editable-page`,
      {},
      {
        headers: getAuthTokenHeader()
      }
    );
  },
  editablePageRead(payload) {
    return wrapper.get(
      `/v1/theme/${payload.themeId}/editable-page/${payload.editablePageId}`,
      {},
      {
        headers: getAuthTokenHeader()
      }
    );
  }
};

export default AUTH_API;
