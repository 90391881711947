"use strict";

import {COMPONENT_API} from "@/api/component.js";

const component = {
  namespaced: true,
  state: {
    componentLoadData: {},
    componentListData: {},
    componentReadData: {},
    themeComponentListData: {}
  },
  mutations: {
    setComponentLoad(state, data) {
      state.componentLoadData = data;
    },
    setComponentList(state, data) {
      state.componentListData = data;
    },
    setComponentRead(state, data) {
      state.componentReadData = data;
    },
    setThemeComponentList(state, data) {
      state.themeComponentListData = data;
    }
  },
  actions: {
    // 컴포넌트 불러오기
    actComponentLoad({commit}, payload) {
      return COMPONENT_API.componentLoad(payload).then(response => {
        commit("setComponentLoad", response.data);
        return response;
      });
    },
    // 컴포넌트 목록
    actComponentList({commit}, payload) {
      return COMPONENT_API.componentList(payload).then(response => {
        commit("setComponentList", response.data);
        return response;
      });
    },
    // 컴포넌트 상세
    actComponentRead({commit}, payload) {
      return COMPONENT_API.componentRead(payload).then(response => {
        commit("setComponentRead", response.data);
        return response;
      });
    },
    // 테마 컴포넌트 목록
    actThemeComponentList({commit}, payload) {
      return COMPONENT_API.themeComponentList(payload).then(response => {
        commit("setThemeComponentList", response.data);
        return response;
      });
    }
  },
  getters: {
    getComponentLoadData(state) {
      return state.componentLoadData;
    },
    getComponentListData(state) {
      return state.componentListData;
    },
    getComponentReadData(state) {
      return state.componentReadData;
    },
    getThemeComponentListData(state) {
      return state.themeComponentListData;
    }
  }
};

export default component;
