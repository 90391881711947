"use strict";

import wrapper from "@/api/wrapper/axios-wrapper.js";
import {getAuthTokenHeader} from "@/utils/auth.js";

export const THEME_API = {
  // 테마 통합 저장하기
  themeSave(payload) {
    let params = {};
    params = JSON.stringify(payload.body);

    return wrapper.post(
      `/v1/theme/${payload.themeId}/save?mode=${payload.mode}`,
      params,
      {
        headers: getAuthTokenHeader("raw")
      }
    );
  },
  // 테마 통합 불러오기
  themeLoad(payload) {
    let params = {};
    params.mode = payload.mode || "user";

    return wrapper.get(`/v1/theme/${payload.themeId}/load`, params, {
      headers: getAuthTokenHeader()
    });
  },
  // 회원 저장 히스토리 목록
  saveHistoryList(payload) {
    let params = {};
    params.perPage = 20;

    return wrapper.get(
      `/v1/user-theme/${payload.themeId}/save-history`,
      params,
      {
        headers: getAuthTokenHeader()
      }
    );
  },
  // 회원 저장 히스토리 삭제
  saveHistoryDelete(payload) {
    return wrapper.delete(
      `/v1/user-theme/${payload.themeId}/save-history/${payload.historyId}`,
      {
        headers: getAuthTokenHeader()
      }
    );
  },
  // 파트너 저장 히스토리 목록
  savePartnerHistoryList(payload) {
    let params = {};
    params.perPage = 20;

    return wrapper.get(`/v1/theme/${payload.themeId}/save-history`, params, {
      headers: getAuthTokenHeader()
    });
  },
  // 파트너 저장 히스토리 삭제
  savePartnerHistoryDelete(payload) {
    return wrapper.delete(
      `/v1/theme/${payload.themeId}/save-history/${payload.historyId}`,
      {
        headers: getAuthTokenHeader()
      }
    );
  },
  // 회원 테마 목록
  userThemeList(payload) {
    return wrapper.get(`/v1/user-theme`, payload, {
      headers: getAuthTokenHeader()
    });
  },
  // 회원 테마 수정
  userThemeUpdate(payload) {
    let params = {};
    if (payload.name) params.name = payload.name;

    return wrapper.patch(`/v1/user-theme/${payload.userThemeId}`, params, {
      headers: getAuthTokenHeader()
    });
  },
  // 회원 테마 삭제
  userThemeDelete(payload) {
    return wrapper.delete(`/v1/user-theme/${payload.userThemeId}`, {
      headers: getAuthTokenHeader()
    });
  },
  // 회원 테마 복사
  userThemeCopy(payload) {
    return wrapper.post(
      `/v1/user-theme/${payload.userThemeId}/replicate`,
      {},
      {headers: getAuthTokenHeader()}
    );
  },
  //회원 테마 통합 생성하기
  userThemeIntegrateCreate(payload) {
    let params = {};
    params.userSite = payload.userSite;
    params.themeName = payload.themeName;

    return wrapper.post(`/v1/theme/${payload.themeId}/create`, params, {
      headers: getAuthTokenHeader()
    });
  },
  // 테마 목록
  themeList(payload) {
    return wrapper.get(
      `/v1/theme-product/${payload.themeProductId}/theme`,
      {},
      {
        headers: getAuthTokenHeader()
      }
    );
  },
  // 테마 상세
  themeRead(payload) {
    return wrapper.get(
      `/v1/theme/${payload.themeId}`,
      {},
      {
        headers: getAuthTokenHeader()
      }
    );
  },
  // 테마 내보내기
  themeExport(payload) {
    let params = {};
    params.userSolutionId = payload.userSolutionId;

    return wrapper.post(`/v1/user-theme/${payload.themeId}/export`, params, {
      headers: getAuthTokenHeader()
    });
  },
  //테마-카테고리 목록
  themeCategory(payload) {
    return wrapper.get(`/v1/theme-product-category`, payload, {
      headers: getAuthTokenHeader()
    });
  },
  //테마 목록(카테고리 검색)
  themeCatalog(payload) {
    return wrapper.get(`/v1/theme`, payload, {
      headers: getAuthTokenHeader()
    });
  },
  // 회원 테마 내보내기
  userThemeExport(payload) {
    let params = {};
    params.userSolutionId = payload.userSolutionId;
    return wrapper.post(`/v1/user-theme/${payload.id}/export`, params, {
      headers: getAuthTokenHeader()
    });
  },
  // 테마 내보내기 내역
  themeExportHistory(payload) {
    return wrapper.get(`/v1/user-theme/export-history`, payload, {
      headers: getAuthTokenHeader()
    });
  },
  // 테마 서체 데이터(파트너)
  themeTypeFaces(payload) {
    return wrapper.get(`/v1/theme/${payload.id}/typefaces`, payload, {
      headers: getAuthTokenHeader()
    });
  },
  // 테마 서체설정(파트너)
  themeTypeFacesUpdate(payload) {
    return wrapper.patch(`/v1/theme/${payload.id}/typefaces`, payload, {
      headers: getAuthTokenHeader()
    });
  }
};

export default THEME_API;
