"use strict";

import {SOLUTION} from "@/api/editor.js";

const editor = {
  namespaced: true,
  state: {
    isAttach: false,
    deleteItems: null,
    themeId: null,
    editablePageId: null,
    componentId: null,
    componentVersionId: null,
    linkedComponentId: [],
    popup: {page: null, value: null, index: null, option: null},
    isPanel: null,
    deviceType: "desktop",
    userDevice: "desktop",
    solutionListData: {},
    iconListData: {},
    productListData: {},
    productCountData: {},
    userThemeHistoryId: null,
    fontEvent: false,
    fontListData: {},
    userFontListData: {},
    userFontUpdateData: {},
    themeFontLoadData: {},
    mode: null
  },
  mutations: {
    setAttach(state, data) {
      state.isAttach = data;
    },
    setDeleteItems(state, data) {
      state.deleteItems = data;
    },
    setThemeId(state, data) {
      state.themeId = data;
    },
    setEditablePageId(state, data) {
      state.editablePageId = data;
    },
    setComponentId(state, data) {
      state.componentId = data;
    },
    setComponentVersionId(state, data) {
      state.componentVersionId = data;
    },
    setLinkedComponentId(state, data) {
      state.linkedComponentId.push(data);
    },
    setPopup(state, {page, index, value, option}) {
      state.popup = {page, index, value, option};
    },
    setPanel(state, data) {
      state.isPanel = data;
    },
    setDeviceType(state, data) {
      state.deviceType = data;
    },
    setUserDevice(state, data) {
      state.userDevice = data;
    },
    setSolutionList(state, data) {
      state.solutionListData = data;
    },
    setIconList(state, data) {
      state.iconListData = data;
    },
    setProductList(state, data) {
      state.productListData = data;
    },
    setProductCount(state, data) {
      state.productCountData = data;
    },
    setUserThemeHistoryId(state, data) {
      state.userThemeHistoryId = data;
    },
    setFontEvent(state, data) {
      state.fontEvent = data;
    },
    setFontList(state, data) {
      state.fontListData = data;
    },
    setUserFontList(state, data) {
      state.userFontListData = data;
    },
    setUserFontUpdate(state, data) {
      state.userFontUpdateData = data;
    },
    setThemeFontLoad(state, data) {
      state.themeFontLoadData = data;
    },
    setMode(state, data) {
      state.mode = data;
    }
  },
  actions: {
    // 솔루션 목록
    actSolutionList({commit}, payload) {
      return SOLUTION.solutionList(payload).then(response => {
        commit("setSolutionList", response.data);
        return response;
      });
    },
    // 아이콘 목록
    actIconList({commit}, payload) {
      return SOLUTION.iconList(payload).then(response => {
        commit("setIconList", response.data);
        return response;
      });
    },
    // 상품 목록
    actProductList({commit}, payload) {
      return SOLUTION.productList(payload).then(response => {
        commit("setProductList", response.data);
        return response;
      });
    },
    // 상품 목록
    actProductCount({commit}, payload) {
      return SOLUTION.productCount(payload).then(response => {
        commit("setProductCount", response.data);
        return response;
      });
    },
    // 서체 목록
    actFontList({commit}, payload) {
      return SOLUTION.fontList(payload).then(response => {
        commit("setFontList", response.data);
        return response;
      });
    },
    // 회원 서체 목록
    actUserFontList({commit}, payload) {
      return SOLUTION.userFontList(payload).then(response => {
        commit("setUserFontList", response.data);
        return response;
      });
    },
    // 회원 서체 수정
    actUserFontUpdate({commit}, payload) {
      return SOLUTION.userFontUpdate(payload).then(response => {
        commit("setUserFontUpdate", response.data);
        return response;
      });
    },
    // 테마 서체 불러오기
    actThemeFontLoad({commit}, payload) {
      return SOLUTION.themeFontLoad(payload).then(response => {
        commit("setThemeFontLoad", response.data);
        return response;
      });
    }
  },
  getters: {
    getAttach(state) {
      return state.isAttach;
    },
    getDeleteItems(state) {
      return state.deleteItems;
    },
    getThemeId(state) {
      return state.themeId;
    },
    getEditablePageId(state) {
      return state.editablePageId;
    },
    getComponentId(state) {
      return state.componentId;
    },
    getComponentVersionId(state) {
      return state.componentVersionId;
    },
    getLinkedComponentId(state) {
      return state.linkedComponentId.pop();
    },
    getPopup(state) {
      return state.popup;
    },
    getPanel(state) {
      return state.isPanel;
    },
    getDeviceType(state) {
      return state.deviceType;
    },
    getUserDevice(state) {
      return state.userDevice;
    },
    getSolutionListData(state) {
      return state.solutionListData;
    },
    getIconListData(state) {
      return state.iconListData;
    },
    getProductListData(state) {
      return state.productListData;
    },
    getProductCountData(state) {
      return state.productCountData;
    },
    getUserThemeHistoryId(state) {
      return state.userThemeHistoryId;
    },
    getFontEvent(state) {
      return state.fontEvent;
    },
    getFontListData(state) {
      return state.fontListData;
    },
    getUserFontListData(state) {
      return state.userFontListData;
    },
    getUserFontUpdateData(state) {
      return state.userFontUpdateData;
    },
    getThemeFontLoadData(state) {
      return state.themeFontLoadData;
    },
    getMode(state) {
      return state.mode;
    }
  }
};

export default editor;
