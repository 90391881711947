var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "material-textfield"
  }, [_c('input', {
    staticClass: "material-textfield__input",
    class: _vm.customClass,
    attrs: {
      "type": _vm.type,
      "maxlength": _vm.maxlength,
      "placeholder": _vm.placeholder,
      "disabled": _vm.disabled,
      "readonly": _vm.readonly
    },
    domProps: {
      "value": _vm.value
    },
    on: {
      "input": function input($event) {
        return _vm.$emit('input', $event.target.value);
      },
      "blur": _vm.validate
    }
  }), _c('label', {
    staticClass: "material-textfield__label"
  }, [_vm._v(_vm._s(_vm.placeholder))])]);
}
var staticRenderFns = []

export { render, staticRenderFns }