"use strict";

import {AUTH_API} from "@/api/user-site.js";

const userSite = {
  namespaced: true,
  state: {
    solutionListData: {},
    userSolutionListData: {},
    userSiteListData: {},
    userSiteReadData: {},
    userSiteAddData: {},
    userSiteDeleteData: {},
    userSiteUpdateData: {}
  },
  mutations: {
    setSolutionList(state, data) {
      state.solutionListData = data;
    },
    setUserSolutionList(state, data) {
      state.userSolutionListData = data;
    },
    setUserSiteList(state, data) {
      state.userSiteListData = data;
    },
    setUserSiteRead(state, data) {
      state.userSiteReadData = data;
    },
    setUserSiteAdd(state, data) {
      state.userSiteAddData = data;
    },
    setUserSiteDelete(state, data) {
      state.userSiteDeleteData = data;
    },
    setUserSiteUpdate(state, data) {
      state.userSiteUpdateData = data;
    }
  },
  actions: {
    // 솔루션 목록
    actSolutionList({commit}, payload) {
      return AUTH_API.solutionList(payload).then(response => {
        commit("setSolutionList", response.data);
        return response;
      });
    },
    // 솔루션 연동정보 목록
    actUserSolutionList({commit}, payload) {
      return AUTH_API.userSolutionList(payload).then(response => {
        commit("setUserSolutionList", response.data);
        return response;
      });
    },
    // 사이트 정보 목록
    actUserSiteList({commit}, payload) {
      return AUTH_API.userSiteList(payload).then(response => {
        commit("setUserSiteList", response.data);
        return response;
      });
    },
    // 사이트 정보 상세
    actUserSiteRead({commit}, payload) {
      return AUTH_API.userSiteRead(payload).then(response => {
        commit("setUserSiteRead", response.data);
        return response;
      });
    },
    // 사이트 정보 추가
    actUserSiteAdd({commit}, payload) {
      return AUTH_API.userSiteAdd(payload).then(response => {
        commit("setUserSiteAdd", response.data);
        return response;
      });
    },
    // 사이트 정보 삭제
    actUserSiteDelete({commit}, payload) {
      return AUTH_API.userSiteDelete(payload).then(response => {
        commit("setUserSiteDelete", response.data);
        return response;
      });
    },
    // 사이트 정보 수정
    actUserSiteUpdate({commit}, payload) {
      return AUTH_API.userSiteUpdate(payload).then(response => {
        commit("setUserSiteUpdate", response.data);
        return response;
      });
    }
  },
  getters: {
    getSolutionListData(state) {
      return state.solutionListData;
    },
    getSolutionId: state => data => {
      let solutionId = null;
      for (let solution of state.solutionListData) {
        if (data === solution.name) {
          solutionId = solution.id;
        }
      }
      return solutionId;
    },
    getUserSolutionListData(state) {
      return state.userSolutionListData;
    },
    getUserSiteListData(state) {
      return state.userSiteListData;
    },
    getUserSiteReadData(state) {
      return state.userSiteReadData;
    },
    getUserSiteAddData(state) {
      return state.userSiteAddData;
    },
    getUserSiteDeleteData(state) {
      return state.userSiteDeleteData;
    },
    getUserSiteUpdateData(state) {
      return state.userSiteUpdateData;
    }
  }
};

export default userSite;
