<template>
  <div class="popup">
    <Dimmed @passEmit="close" />
    <div
      class="pop-wrap"
      :class="customClass"
      :style="{width: Width, height: Height}"
    >
      <div class="pop-header">
        <div class="pop-title">
          <slot name="title" />
        </div>
        <button type="button" class="btn-close" @click="close">
          <span class="blind">팝업 닫기 버튼</span>
        </button>
      </div>
      <div class="pop-content">
        <slot> Slot이 전달되지 않았습니다. </slot>
      </div>
      <div v-if="$slots.footer" class="pop-footer" @click="save">
        <slot name="footer" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Popup",
  props: {
    customClass: {
      type: String,
      default: "data_pop"
    },
    width: {
      type: [Number, String]
    },

    height: {
      type: [Number, String]
    }
  },
  computed: {
    Width() {
      if (this.width) {
        return isNaN(Number(this.width)) ? this.width : `${this.width}px`;
      } else {
        return null;
      }
    },

    Height() {
      if (this.height) {
        return isNaN(Number(this.height)) ? this.height : `${this.height}px`;
      } else {
        return null;
      }
    }
  },
  mounted() {
    // 팝업 mobile height
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--height", `${vh}px`);
    window.addEventListener("resize", this.resizeHeight);
  },
  beforeDestroy() {
    document.documentElement.style.setProperty("--height", ``);
    window.removeEventListener("resize", this.resizeHeight);
  },
  methods: {
    close() {
      this.$emit("popupClose");
    },
    save() {
      this.$emit("popupSave");
    },
    resizeHeight() {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--height", `${vh}px`);
    }
  }
};
</script>

<style lang="scss" scoped>
.popup {
  .pop-wrap {
    position: fixed;
    background-color: #fff;
    z-index: 10000002;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 572px;
    height: auto;
    border-radius: 8px;
    overflow: hidden;

    @media screen and (max-width: 1023px) {
      width: 100%;
      height: calc(var(--height, 1vh) * 100);
      border-radius: 0px;
    }

    .pop-header {
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
      height: 56px;
      padding: 0 16px 0 24px;
      display: flex;
      align-items: center;
      margin-bottom: 4px;

      .pop-title {
        font-weight: 500;
        font-size: 16px;
      }

      .btn-close {
        margin-left: auto;
        width: 24px;
        height: 24px;
        background-image: url("~@/assets/images/common/btn_close.svg");
        background-repeat: no-repeat;
        background-position: 50% 50%;
        text-indent: -9999px;
      }
    }

    .pop-content {
      background: #f7f9fc;
      height: 550px;
      overflow-y: auto;
      @media screen and (max-width: 1023px) {
        height: calc(var(--height, 1vh) * 100 - 56px);
      }
    }

    .pop-footer {
      height: 64px;
      background: #54c7a2;
      font-size: 18px;
      color: #fff;
      cursor: pointer;
      text-align: center;
      line-height: 64px;
      font-weight: 500;
      bottom: 0px;
      position: absolute;
      width: 100%;
    }
  }
}
</style>
