var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "frmThumb"
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.attach.loading,
      expression: "attach.loading"
    }],
    staticClass: "frmThumb__loader"
  }, [_c('i', {
    staticClass: "xi-spinner-1 xi-spin"
  })]), _vm.attach.url ? _c('div', {
    staticClass: "frmThumb__attach"
  }, [_c('button', {
    attrs: {
      "type": "button"
    }
  }, [_vm._v("이미지 삭제")]), _c('img', {
    attrs: {
      "src": _vm.attach.url
    },
    on: {
      "click": _vm.pickImage
    }
  })]) : _c('button', {
    staticClass: "frmThumb__select",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.pickImage
    }
  }, [_vm._v(" 이미지 선택 ")])]);
}
var staticRenderFns = []

export { render, staticRenderFns }