var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "frmBlock__dropdownField"
  }, [_c('div', {
    staticClass: "DAV-dropdown",
    class: {
      'DAV-dropdown-small': _vm.type === 'small'
    }
  }, [_c('button', {
    staticClass: "DAV-dropdown__button",
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        _vm.isVisible = true;
      }
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.selectValue))])]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isVisible,
      expression: "isVisible"
    }],
    staticClass: "DAV-dropdown__list"
  }, [_c('div', {
    staticClass: "DAV-dropdown__selected"
  }, [_vm._v(" " + _vm._s(_vm.selectValue) + " ")]), _c('ul', {
    directives: [{
      name: "click-outside",
      rawName: "v-click-outside:toggleLayer",
      arg: "toggleLayer"
    }]
  }, _vm._l(_vm.options, function (option, index) {
    return _c('li', {
      key: index
    }, [_c('button', {
      class: {
        'select-active': option.text === _vm.selectValue
      },
      attrs: {
        "type": "button"
      },
      on: {
        "click": function click($event) {
          _vm.$emit('updateTarget', option.value);

          _vm.isVisible = false;
        }
      }
    }, [_vm._v(" " + _vm._s(option.text) + " ")])]);
  }), 0)])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }