<template>
  <div class="tooltip" @click.stop="popupShow">
    <div
      ref="content"
      class="tooltip__default-icon tooltip__content"
      :style="`color: ${color}`"
    >
      <i class="xi-help-o"></i>
    </div>
    <div
      class="tooltip__box"
      v-show="isShow"
      :style="`top: ${top};left: ${left}`"
      v-click-outside:popupHide
    >
      <div class="tooltip__message">
        <p v-if="title" class="tooltip__message-title" v-html="title" />
        <p v-if="text" class="tooltip__message-text" v-html="text" />
        <slot />
        <i class="xi-close tooltip__message-close" @click.stop="popupHide"></i>
      </div>
    </div>
  </div>
</template>

<script>
import {createNamespacedHelpers} from "vuex";
const ModuleAdmin = createNamespacedHelpers("ModuleAdmin");

export default {
  name: "Tooltip",
  data() {
    return {
      isShow: false
    };
  },
  props: {
    color: {
      type: String,
      default: "#b7b7b7"
    },
    top: {
      type: String,
      default: "45px"
    },
    left: {
      type: String,
      default: "0px"
    },
    title: {
      type: String
    },
    text: {
      type: String
    }
  },
  computed: {
    ...ModuleAdmin.mapGetters(["getToolTip"])
  },
  watch: {
    getToolTip(v) {
      if (!v.toolTip) {
        this.isShow = false;
      }
    }
  },
  methods: {
    popupShow() {
      if (this.isShow) return;
      if (this.getToolTip) {
        this.setToolTip({toolTip: false});
      }
      setTimeout(() => {
        this.setToolTip({toolTip: true});
        this.isShow = true;
      });
    },
    popupHide() {
      this.setToolTip({toolTip: false});
    },
    ...ModuleAdmin.mapMutations(["setToolTip"])
  }
};
</script>

<style lang="scss">
.tooltip {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
  margin-left: 2px;
  color: #b7b7b7;

  &__content {
    cursor: help;
    position: relative;
    &--outer-cover {
      width: 100%;
      height: 100%;
      position: absolute;
    }
    &--inner-cover {
      width: 100%;
      height: 100%;
      position: relative;
      z-index: 1;
    }
  }

  &__default-icon {
    cursor: help;
    color: #b7b7b7;
    font-size: 17px;
  }

  &__box {
    position: absolute;
    top: 45px;
    left: 0px;
    min-width: 10px;
    cursor: default;
    color: white;
    z-index: 3;
  }

  &__message {
    padding: 12px 32px 12px 12px;
    background-color: #ffffff;
    border: 1px solid #d8dae5;
    box-sizing: border-box;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
    border-radius: 8px;
    @media screen and (min-width: 1024px) {
      max-width: 380px;
    }
    @media screen and (max-width: 1023px) {
      min-width: 250px;
    }
    &-title {
      font-weight: 500 !important;
      font-size: 14px !important;
      color: #484948;
      & + * {
        margin-top: 8px;
      }
    }
    &-text {
      word-break: keep-all;
      line-height: 1.5;
      font-weight: normal !important;
      color: #484948;
      font-size: 12px;
    }

    &-close {
      color: #484948;
      font-size: 12px;
      position: absolute;
      top: 12px;
      right: 12px;
      cursor: pointer;
    }

    .bold {
      text-decoration: underline;
      font-size: 14px;
    }
  }
}
</style>
