<template>
  <div id="app">
    <router-view></router-view>
    <alert v-if="getAlertVisible"></alert>
    <confirm v-if="getConfirmVisible"></confirm>
    <Spinner
      v-if="
        $store.state.ModuleSpinner.spinner.isVisible &&
        this.$route.name !== 'editor' &&
        this.$route.name !== 'option'
      "
    ></Spinner>
  </div>
</template>

<script>
import {createNamespacedHelpers} from "vuex";
const ModuleAlert = createNamespacedHelpers("ModuleAlert");
const ModuleConfirm = createNamespacedHelpers("ModuleConfirm");

export default {
  computed: {
    ...ModuleAlert.mapGetters(["getAlertVisible", "getCallback"]),
    ...ModuleConfirm.mapGetters(["getConfirmVisible"])
  },
  watch: {
    getAlertVisible(newVal) {
      //getCallback: 비로그인 상태로 회원메뉴 접근시 설정된 페이지로 이동
      if (!newVal && this.getCallback) {
        if (!isNaN(this.getCallback)) {
          this.$router.go(this.getCallback) || window.close();
        } else {
          this.$router.replace(this.getCallback);
        }
        this.setCallback(null);
      }
    }
  },
  methods: {
    ...ModuleAlert.mapMutations(["setCallback"])
  },
  components: {
    alert: () => import("@/components/basis/alert.vue"),
    confirm: () => import("@/components/basis/confirm.vue"),
    Spinner: () => import("@/components/basis/spinner.vue")
  }
};
</script>

<style></style>
