<template>
  <div class="frmBlock__textField--wrap">
    <input
      class="frmBlock__textField"
      :type="type"
      :placeholder="placeholder"
      :value="value"
      :maxlength="maxlength"
      @input="$emit('input', $event.target.value)"
    />
    <div v-if="maxlength" class="frmBlock__count">
      <em>{{ value ? value.length : 0 }}</em>
      /{{ maxlength }}
    </div>
  </div>
</template>

<script>
export default {
  name: "TextField",
  props: {
    type: {
      type: String,
      default: "text"
    },
    placeholder: {
      type: String
    },
    value: {
      type: String
    },
    maxlength: {
      type: Number
    }
  },
  data() {
    return {};
  }
};
</script>

<style lang="scss" scoped>
.frmBlock__textField--wrap {
  width: 100%;
}
</style>
