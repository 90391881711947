"use strict";

import {AUTH_API} from "@/api/accounts/auth.js";

const auth = {
  namespaced: true,
  state: {
    loginData: {},
    loggedData: {},
    logoutData: {},
    loginStatus: false //로그인 유무 체크용 상태값
  },
  mutations: {
    setLogin(state, data) {
      state.loginData = data;
    },
    setLogged(state, data) {
      state.loggedData = data;
    },
    setLogout(state, data) {
      state.logoutData = data;
    },
    setLoginStatus(state, data) {
      state.loginStatus = data;
    }
  },
  actions: {
    // 로그인
    actLogin({commit}, payload) {
      return AUTH_API.login(payload).then(response => {
        commit("setLogin", response.data);
        return response;
      });
    },
    // 로그인된 회원정보
    actLogged({commit}) {
      return AUTH_API.logged().then(response => {
        commit("setLogged", response.data);
        localStorage.setItem("userId", response.data.id);
        return response;
      });
    },
    // 로그아웃
    actLogout({commit}) {
      return AUTH_API.logout().then(response => {
        commit("setLogout", response.data);
        localStorage.removeItem("userId");
        return response;
      });
    }
  },
  getters: {
    getLoginData(state) {
      return state.loginData;
    },
    getLoggedData(state) {
      return state.loggedData;
    },
    getLogoutData(state) {
      return state.logoutData;
    },
    getLoginStatus(state) {
      return state.loginStatus;
    }
  }
};

export default auth;
