"use strict";

const editorController = {
  namespaced: true,
  state: {
    event: null,
    optionEvent: null,
    editableIndex: 0,
    editablePages: {},
    componentLayout: {},
    componentContainer: {}
  },
  mutations: {
    setEvent(state, data) {
      state.event = data;
    },
    setOptionEvent(state, data) {
      state.optionEvent = data;
    },
    setEditableIndex(state, data) {
      state.editableIndex = data;
    },
    setEditablePages(state, data) {
      state.editablePages = data;
    },
    setComponentLayout(state, data) {
      state.componentLayout[state.editableIndex] = data;
    },
    setComponentLayouts(state, data) {
      state.componentLayout = data;
    },
    setComponentContainer(state, data) {
      state.componentContainer[state.editableIndex] = data;
    },
    setComponentContainers(state, data) {
      state.componentContainer = data;
    }
  },
  getters: {
    getEvent(state) {
      return state.event;
    },
    getOptionEvent(state) {
      return state.optionEvent;
    },
    getEditableIndex(state) {
      return state.editableIndex;
    },
    getEditablePages(state) {
      return state.editablePages;
    },
    getEditablePage(state) {
      return state.editablePages[state.editableIndex];
    },
    getEditablePageLayout: state => data => {
      if (state.editablePages[state.editableIndex].layouts.header === data) {
        return "header";
      }
      if (state.editablePages[state.editableIndex].layouts.content === data) {
        return "content";
      }
      if (state.editablePages[state.editableIndex].layouts.footer === data) {
        return "footer";
      }
    },
    getComponentLayout(state) {
      return state.componentLayout[state.editableIndex];
    },
    getComponentLayouts(state) {
      return state.componentLayout;
    },
    getComponentContainer(state) {
      return state.componentContainer[state.editableIndex];
    },
    getComponentContainers(state) {
      return state.componentContainer;
    }
  }
};

export default editorController;
