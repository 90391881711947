"use strict";

const alert = {
  namespaced: true,
  state: {
    alert: {
      isVisible: false,
      message: null,
      event: null,
      callback: null
    }
  },
  mutations: {
    setAlertVisible(state, data) {
      state.alert.isVisible = data;
    },
    setAlertMessage(state, data) {
      state.alert.message = data;
    },
    setAlertEvent(state, data) {
      state.alert.event = data;
    },
    setAlert(state, data) {
      state.alert = data;
    },
    setCallback(state, data) {
      state.callback = data;
    }
  },
  getters: {
    getAlertVisible(state) {
      return state.alert.isVisible;
    },
    getAlertMessage(state) {
      return state.alert.message;
    },
    getAlertEvent(state) {
      return state.alert.event;
    },
    getAlert(state) {
      return state.alert;
    },
    getCallback(state) {
      return state.callback;
    }
  }
};

export default alert;
