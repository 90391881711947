"use strict";

import wrapper from "@/api/wrapper/axios-wrapper.js";
import {getAuthTokenHeader} from "@/utils/auth.js";

export const AUTH_API = {
  // 솔루션 목록
  solutionList() {
    return wrapper.get(
      `/v1/solution`,
      {},
      {
        headers: getAuthTokenHeader()
      }
    );
  },
  // 솔루션 연동정보 목록
  userSolutionList(payload) {
    return wrapper.get(
      `/v1/user/${payload.userId}/solution`,
      {},
      {
        headers: getAuthTokenHeader()
      }
    );
  },
  // 사이트 정보 목록
  userSiteList(payload) {
    let params = {};
    if (payload.isSetSolution) params.isSetSolution = payload.isSetSolution;

    return wrapper.get(`/v1/user/${payload.userId}/site`, params, {
      headers: getAuthTokenHeader()
    });
  },
  // 사이트 정보 상세
  userSiteRead(payload) {
    return wrapper.get(
      `/v1/user/${payload.userId}/site/${payload.siteId}`,
      {},
      {
        headers: getAuthTokenHeader()
      }
    );
  },
  // 사이트 정보 추가
  userSiteAdd(payload) {
    return wrapper.post(`/v1/user/${payload.userId}/site`, payload, {
      headers: getAuthTokenHeader()
    });
  },
  // 사이트 정보 삭제
  userSiteDelete(payload) {
    return wrapper.delete(`/v1/user/${payload.userId}/site/${payload.siteId}`, {
      headers: getAuthTokenHeader()
    });
  },
  // 사이트 정보 수정
  userSiteUpdate(payload) {
    return wrapper.patch(
      `/v1/user/${payload.userId}/site/${payload.siteId}`,
      payload,
      {
        headers: getAuthTokenHeader()
      }
    );
  }
};

export default AUTH_API;
