"use strict";

import {THEME_PRODUCT_API} from "@/api/theme-product.js";

const themeProduct = {
  namespaced: true,
  state: {
    themeProductListData: {},
    themeProductReadData: {}
  },
  mutations: {
    setThemeProductList(state, data) {
      state.themeProductListData = data;
    },
    setThemeProductRead(state, data) {
      state.themeProductReadData = data;
    }
  },
  actions: {
    // 테마 상품 목록
    actThemeProductList({commit}, payload) {
      return THEME_PRODUCT_API.themeProductList(payload).then(response => {
        commit("setThemeProductList", response.data);
        return response;
      });
    },
    // 테마 상품 상세
    actThemeProductRead({commit}, payload) {
      return THEME_PRODUCT_API.themeProductRead(payload).then(response => {
        commit("setThemeProductRead", response.data);
        return response;
      });
    }
  },
  getters: {
    getThemeProductListData(state) {
      return state.themeProductListData;
    },
    getThemeProductReadData(state) {
      return state.themeProductReadData;
    }
  }
};

export default themeProduct;
