"use strict";

import wrapper from "@/api/wrapper/axios-wrapper.js";
import {getAuthTokenHeader} from "@/utils/auth.js";

export const ALARM_API = {
  // 알림 목록
  alarmList(payload) {
    return wrapper.get(`/v1/notification`, payload, {
      headers: getAuthTokenHeader()
    });
  },
  // 알림 확인
  alarmCheckUpdate(payload) {
    return wrapper.patch(
      `/v1/notification/${payload.id}/check`,
      {},
      {headers: getAuthTokenHeader()}
    );
  },
  // 알림 전체 확인
  alarmCheckAllUpdate() {
    return wrapper.patch(
      `/v1/notification/check-all`,
      {},
      {headers: getAuthTokenHeader()}
    );
  }
};

export default ALARM_API;
