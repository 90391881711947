"use strict";

import wrapper from "@/api/wrapper/axios-wrapper.js";
import {getAuthTokenHeader} from "@/utils/auth.js";

export const AUTH_API = {
  // 연동 컴포넌트 상세(에디터용)
  componentOptionList(payload) {
    return wrapper.get(
      `/v1/linked-component/${payload.linkedComponentId}`,
      {},
      {
        headers: getAuthTokenHeader()
      }
    );
  },
  // 컴포넌트 옵션 상세
  componentOptionRead(payload) {
    let params = {};
    if (payload.withOptionProperties)
      params.withOptionProperties = payload.withOptionProperties;

    return wrapper.get(
      `/v1/component-option/${payload.linkedComponentOptionId}`,
      params,
      {
        headers: getAuthTokenHeader()
      }
    );
  },
  // 연동 컴포넌트 옵션 수정
  componentOptionUpdate(payload) {
    let params = {};
    if (payload.value) params.value = payload.value;

    return wrapper.patch(
      `/v1/theme/${payload.themeId}/editable-page/${payload.editablePageId}/linked-component/${payload.linkedComponentId}/option/${payload.linkedComponentOptionId}`,
      params,
      {
        headers: getAuthTokenHeader()
      }
    );
  },
  // 컴포넌트 옵션 목록(파트너용)
  partnerComponentOptionList(payload) {
    let params = {};
    if (payload.sortBy) params.sortBy = payload.sortBy;
    if (payload.withOptionProperties)
      params.withOptionProperties = payload.withOptionProperties;

    return wrapper.get(
      `/v1/component/${payload.componentId}/version/${payload.versionId}/option`,
      params,
      {
        headers: getAuthTokenHeader()
      }
    );
  },
  // 컴포넌트 옵션 프로퍼티 기본값 수정(파트너용)
  partnerPropertyUpdate(payload) {
    let params = {};
    if (payload.options) params.options = payload.options;

    return wrapper.patch(
      `/v1/component/${payload.componentId}/version/${payload.versionId}/init-data`,
      params,
      {
        headers: getAuthTokenHeader()
      }
    );
  }
};

export default AUTH_API;
