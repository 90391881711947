var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "app"
    }
  }, [_c('router-view'), _vm.getAlertVisible ? _c('alert') : _vm._e(), _vm.getConfirmVisible ? _c('confirm') : _vm._e(), _vm.$store.state.ModuleSpinner.spinner.isVisible && this.$route.name !== 'editor' && this.$route.name !== 'option' ? _c('Spinner') : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }