"use strict";

import wrapper from "@/api/wrapper/axios-wrapper.js";
import {getAuthTokenHeader} from "@/utils/auth.js";

export const THEME_PRODUCT_API = {
  // 테마 상품 목록
  themeProductList(payload) {
    return wrapper.get(`/v1/theme-product`, payload, {
      headers: getAuthTokenHeader()
    });
  },
  // 테마 상품 상세
  themeProductRead(payload) {
    return wrapper.get(
      `/v1/theme-product/${payload.themeProductId}`,
      {},
      {
        headers: getAuthTokenHeader()
      }
    );
  }
};

export default THEME_PRODUCT_API;
