"use strict";

import wrapper from "@/api/wrapper/axios-wrapper.js";
import {getAuthTokenHeader} from "@/utils/auth.js";

export const ATTACH_API = {
  // 첨부파일 임시 저장
  attachAdd(payload) {
    let params = new FormData();
    if (payload.files) params.append("files", payload.files);

    return wrapper.post(`/v1/attach`, params, {
      headers: getAuthTokenHeader("attach")
    });
  },
  // 첨부파일 삭제
  attachDelete(payload) {
    return wrapper.delete(`/v1/attach/${payload.id}`, {
      headers: getAuthTokenHeader()
    });
  },
  // 첨부파일 수정
  attachUpdate(payload) {
    let params = {};
    if (payload.type) params.type = payload.type;
    if (payload.typeId) params.typeId = payload.typeId;
    if (payload.thumbnail) params.thumbnail = payload.thumbnail;

    return wrapper.patch(`/v1/attach/${payload.id}`, params, {
      headers: getAuthTokenHeader()
    });
  },
  // 파트너 이미지 목록
  partnerImageList(payload) {
    return wrapper.get(`/v1/partner-upload-image`, payload, {
      headers: getAuthTokenHeader()
    });
  },
  // 파트너 이미지 업로드
  partnerImageAdd(payload) {
    let params = new FormData();
    if (payload.files) params.append("files", payload.files);

    return wrapper.post(`/v1/partner-upload-image`, params, {
      headers: getAuthTokenHeader("attach")
    });
  },
  // 파트너 이미지 삭제
  partnerImageDelete(payload) {
    return wrapper.delete(`/v1/partner-upload-image/${payload.id}`, {
      headers: getAuthTokenHeader()
    });
  },
  // 파트너 이미지 사용량 조회
  partnerImageUsage(payload) {
    return wrapper.get(`/v1/partner-upload-image/usage`, payload, {
      headers: getAuthTokenHeader()
    });
  },
  // 파트너 이미지 상세
  partnerImageRead(payload) {
    return wrapper.get(
      `/v1/partner-upload-image/${payload.id}`,
      {},
      {
        headers: getAuthTokenHeader()
      }
    );
  },
  // 컴포넌트 이미지 목록
  componentImageList(payload) {
    return wrapper.get(`/v1/component-upload-image`, payload, {
      headers: getAuthTokenHeader()
    });
  },
  // 컴포넌트 이미지 업로드
  componentImageAdd(payload) {
    let params = new FormData();
    if (payload.files) params.append("files", payload.files);

    return wrapper.post(`/v1/component-upload-image`, params, {
      headers: getAuthTokenHeader("attach")
    });
  },
  // 컴포넌트 이미지 삭제
  componentImageDelete(payload) {
    return wrapper.delete(`/v1/component-upload-image/${payload.id}`, {
      headers: getAuthTokenHeader()
    });
  },
  // 컴포넌트 이미지 사용량 조회
  componentImageUsage(payload) {
    return wrapper.get(`/v1/component-upload-image/usage`, payload, {
      headers: getAuthTokenHeader()
    });
  },
  // 컴포넌트 이미지 상세
  componentImageRead(payload) {
    return wrapper.get(
      `/v1/component-upload-image/${payload.id}`,
      {},
      {
        headers: getAuthTokenHeader()
      }
    );
  },
  // unsplash 이미지 목록
  unsplashImageList(payload) {
    return wrapper.get(`/v1/unsplash`, payload, {
      headers: getAuthTokenHeader()
    });
  },
  // unsplash 이미지 검색 목록
  unsplashSearchImageList(payload) {
    return wrapper.get(`/v1/unsplash/search`, payload, {
      headers: getAuthTokenHeader()
    });
  },
  // unsplash 이미지 상세
  unsplashImageRead(payload) {
    return wrapper.get(`/v1/unsplash/image/${payload.id}`, payload, {
      headers: getAuthTokenHeader()
    });
  },
  // unsplash 이미지 다운
  unsplashImageDownload(payload) {
    return wrapper.post(`/v1/unsplash/image/${payload.id}`, payload, {
      headers: getAuthTokenHeader()
    });
  }
};

export default ATTACH_API;
