var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "popup"
  }, [_c('Dimmed', {
    on: {
      "passEmit": _vm.close
    }
  }), _c('div', {
    staticClass: "pop-wrap",
    class: _vm.customClass,
    style: {
      width: _vm.Width,
      height: _vm.Height
    }
  }, [_c('div', {
    staticClass: "pop-header"
  }, [_c('div', {
    staticClass: "pop-title"
  }, [_vm._t("title")], 2), _c('button', {
    staticClass: "btn-close",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.close
    }
  }, [_c('span', {
    staticClass: "blind"
  }, [_vm._v("팝업 닫기 버튼")])])]), _c('div', {
    staticClass: "pop-content"
  }, [_vm._t("default", function () {
    return [_vm._v(" Slot이 전달되지 않았습니다. ")];
  })], 2), _vm.$slots.footer ? _c('div', {
    staticClass: "pop-footer",
    on: {
      "click": _vm.save
    }
  }, [_vm._t("footer")], 2) : _vm._e()])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }