"use strict";

const confirm = {
  namespaced: true,
  state: {
    confirm: {
      isVisible: false,
      message: null,
      messageBtn: null,
      event: null
    }
  },
  mutations: {
    setConfirmVisible(state, data) {
      state.confirm.isVisible = data;
    },
    setConfirmMessage(state, data) {
      state.confirm.message = data;
    },
    setConfirmBtnMessage(state, data) {
      state.confirm.messageBtn = data;
    },
    setConfirmEvent(state, data) {
      state.confirm.event = data;
    },
    setConfirm(state, data) {
      state.confirm = data;
    }
  },
  getters: {
    getConfirmVisible(state) {
      return state.confirm.isVisible;
    },
    getConfirmMessage(state) {
      return state.confirm.message;
    },
    getConfirmBtnMessage(state) {
      return state.confirm.messageBtn;
    },
    getConfirmEvent(state) {
      return state.confirm.event;
    },
    getConfirm(state) {
      return state.confirm;
    }
  }
};

export default confirm;
