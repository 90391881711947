"use strict";

const commonUtils = {
  namespaced: true,
  setCookie(tokenKey, tokenValue) {
    document.cookie =
      tokenKey + "=" + tokenValue + "; expire=0; path=/; domain=.qpicki.com";
  },
  getCookie(tokenKey) {
    let value = document.cookie.match("(^|;) ?" + tokenKey + "=([^;]*)(;|$)");
    return value ? unescape(value[2]) : null;
  },
  deleteCookie(tokenKey) {
    document.cookie =
      tokenKey +
      "=; expire=Thu, 01 Jan 1999 00:00:00 GMT; path=/; domain=.qpicki.com";
  },
  getQueryString(key) {
    return new URLSearchParams(location.search).get(key);
  }
};

export default commonUtils;
