var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "frmBlock__textField--wrap"
  }, [_c('input', {
    staticClass: "frmBlock__textField",
    attrs: {
      "type": _vm.type,
      "placeholder": _vm.placeholder,
      "maxlength": _vm.maxlength
    },
    domProps: {
      "value": _vm.value
    },
    on: {
      "input": function input($event) {
        return _vm.$emit('input', $event.target.value);
      }
    }
  }), _vm.maxlength ? _c('div', {
    staticClass: "frmBlock__count"
  }, [_c('em', [_vm._v(_vm._s(_vm.value ? _vm.value.length : 0))]), _vm._v(" /" + _vm._s(_vm.maxlength) + " ")]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }