"use strict";

import wrapper from "@/api/wrapper/axios-wrapper.js";
import {getAuthTokenHeader} from "@/utils/auth.js";

export const AUTH_API = {
  login(payload) {
    payload.grantType = process.env.VUE_APP_AUTH_PASSWORD;
    payload.clientId = process.env.VUE_APP_AUTH_CLIENT_ID;
    payload.clientSecret = process.env.VUE_APP_AUTH_SECRET;

    return wrapper.post("/v1/user/auth", payload);
  },

  logged() {
    return wrapper.get(
      "/v1/user/auth",
      {},
      {
        headers: getAuthTokenHeader()
      }
    );
  },

  logout() {
    return wrapper.delete("/v1/user/auth", {
      headers: getAuthTokenHeader()
    });
  }
};

export default AUTH_API;
