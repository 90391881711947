"use strict";

import {BOARD_API} from "@/api/board.js";

const board = {
  namespaced: true,
  state: {
    boardListData: {},
    boardNoticeListData: {},
    boardUsetipListData: {},
    boardFaqListData: {},
    boardFindPageData: {}
  },
  mutations: {
    setBoardList(state, data) {
      state.boardListData = data;
    },
    setBoardNoticeList(state, data) {
      state.boardNoticeListData = data;
    },
    setBoardUsetipList(state, data) {
      state.boardUsetipListData = data;
    },
    setBoardFaqList(state, data) {
      state.boardFaqListData = data;
    },
    setBoardFindPage(state, data) {
      state.boardFindPageDataq = data;
    }
  },
  actions: {
    // 게시판 글 목록
    actBoardList({commit}, payload) {
      return BOARD_API.boardList(payload).then(response => {
        commit("setBoardList", response.data);
        return response;
      });
    },
    // 게시판 공지사항 목록
    actBoardNoticeList({commit}, payload) {
      return BOARD_API.boardNoticeList(payload).then(response => {
        commit("setBoardNoticeList", response.data);
        return response;
      });
    },
    // 게시판 사용팁 목록
    actBoardUsetipList({commit}, payload) {
      return BOARD_API.boardUsetipList(payload).then(response => {
        commit("setBoardUsetipList", response.data);
        return response;
      });
    },
    // 게시판 faq 목록
    actBoardFaqList({commit}, payload) {
      return BOARD_API.boardFaqList(payload).then(response => {
        commit("setBoardFaqList", response.data);
        return response;
      });
    },
    // 게시판 페이지수 계산
    actBoardFindPage({commit}, payload) {
      return BOARD_API.boardFindPage(payload).then(response => {
        commit("setBoardFindPage", response.data);
        return response;
      });
    }
  },
  getters: {
    getBoardListData(state) {
      return state.boardListData;
    },
    getBoardNoticeListData(state) {
      return state.boardNoticeListData;
    },
    getBoardUsetipListData(state) {
      return state.boardUsetipListData;
    },
    getBoardFaqListData(state) {
      return state.boardFaqListData;
    },
    getBoardFindPageData(state) {
      return state.boardFindPageData;
    }
  }
};

export default board;
