import InputField from "@/components/ui/inputField";
import TextField from "@/components/options/unit/TextField";
import ImagePicker from "@/components/options/unit/ImagePicker";
import Dropdown from "@/components/options/unit/Dropdown";
import Tooltip from "@/components/ui/Tooltip";
import Popup from "@/components/basis/popup";
import Dimmed from "@/components/basis/dimmed";
import Spinner from "@/components/basis/spinner";

const components = [
  InputField,
  TextField,
  ImagePicker,
  Dropdown,
  Tooltip,
  Popup,
  Dimmed,
  Spinner
];

const GlobalComponent = {
  install(Vue) {
    components.forEach(component => {
      Vue.component(component.name, component);
    });
  }
};

export default GlobalComponent;
