"use strict";

import wrapper from "@/api/wrapper/axios-wrapper.js";
import {getAuthTokenHeader} from "@/utils/auth.js";

export const LINKEDCOMPONENT_API = {
  // 연동 컴포넌트 목록
  linkedComponentList(payload) {
    return wrapper.get(
      `/v1/theme/${payload.themeId}/editable-page/${payload.editablePageId}/linked-component`,
      {},
      {
        headers: getAuthTokenHeader()
      }
    );
  },
  // 연동 컴포넌트 상세
  linkedComponentRead(payload) {
    let params = {};
    if (payload.withRenderData) params.withRenderData = payload.withRenderData;
    if (payload.withOptions) params.withOptions = payload.withOptions;

    return wrapper.get(
      `/v1/theme/${payload.themeId}/editable-page/${payload.editablePageId}/linked-component/${payload.linkedComponentId}`,
      params,
      {
        headers: getAuthTokenHeader()
      }
    );
  },
  // 연동 컴포넌트 수정
  linkedComponentUpdate(payload) {
    let params = {};
    if (payload.name) params.name = payload.name;
    if (payload.sort) params.sort = payload.sort;
    if (payload.linkedComponentGroupId)
      params.linkedComponentGroupId = payload.linkedComponentGroupId;
    if (payload.etc) params.etc = payload.etc;
    params.displayOnPc = payload.displayOnPc;
    params.displayOnMobile = payload.displayOnMobile;
    params.visible = payload.visible;

    return wrapper.patch(
      `/v1/theme/${payload.themeId}/editable-page/${payload.editablePageId}/linked-component/${payload.linkedComponentId}`,
      params,
      {
        headers: getAuthTokenHeader()
      }
    );
  },
  // 연동 컴포넌트 삭제
  linkedComponentDelete(payload) {
    return wrapper.delete(
      `/v1/theme/${payload.themeId}/editable-page/${payload.editablePageId}/linked-component/${payload.linkedComponentId}`,
      {
        headers: getAuthTokenHeader()
      }
    );
  },
  // 연동 컴포넌트 관계형 등록
  linkedComponentAdd(payload) {
    let params = {};
    if (payload.linkedComponentGroupId)
      params.linkedComponentGroupId = payload.linkedComponentGroupId; // 연동 컴포넌트 그룹 id (required)
    if (payload.componentId) params.componentId = payload.componentId; // 컴포넌트 id (required)
    if (payload.name) params.name = payload.name; // 컴포넌트명

    return wrapper.post(
      `/v1/theme/${payload.themeId}/editable-page/${payload.editablePageId}/relational-linked-component`,
      params,
      {
        headers: getAuthTokenHeader()
      }
    );
  }
};

export default LINKEDCOMPONENT_API;
