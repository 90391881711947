"use strict";

import {AUTH_API} from "@/api/editable-page.js";

const editablePage = {
  namespaced: true,
  state: {
    editablePageListData: {},
    editablePageReadData: {}
  },
  mutations: {
    setEditablePageList(state, data) {
      state.editablePageListData = data;
    },
    setEditablePageRead(state, data) {
      state.editablePageReadData = data;
    }
  },
  actions: {
    // 에디터 지원 페이지 목록
    actEditablePageList({commit}, payload) {
      return AUTH_API.editablePageList(payload).then(response => {
        commit("setEditablePageList", response.data);
        return response;
      });
    },
    // 에디터 지원 페이지 상세
    actEditablePageRead({commit}, payload) {
      return AUTH_API.editablePageRead(payload).then(response => {
        commit("setEditablePageRead", response.data);
        return response;
      });
    }
  },
  getters: {
    getEditablePageListData(state) {
      return state.editablePageListData;
    },
    getEditablePageReadData(state) {
      return state.editablePageReadData;
    }
  }
};

export default editablePage;
