import {AUTH_API} from "@/api/accounts/auth.js";
import store from "@/store";
import {getAuthToken} from "@/utils/auth.js";

const requireAuth = () => (from, to, next) => {
  return AUTH_API.logged().then(response => {
    if (response.status == "200") {
      if (response.data.emailVerifiedAt === null && response.data.grade === 1) {
        return next(
          `/accounts/signup/certify/notice?email=${response.data.privacy.email}`
        );
      }
      return next();
    } else {
      let authToken = getAuthToken();
      if (!authToken) {
        store.commit("ModuleAlert/setCallback", "/accounts/login");
        store.commit("ModuleAlert/setAlert", {
          isVisible: true,
          message: `로그인이 필요한 서비스입니다.<br>로그인 후 이용해주세요.`
        });
      }
    }
  });
};
export default [
  {
    name: "login-new",
    path: "/accounts/login",
    component: () => import("@/views/accounts/Login.vue")
  },
  {
    name: "logout",
    path: "/accounts/logout",
    component: () => import("@/views/accounts/Logout.vue")
  },
  {
    name: "signup",
    path: "/accounts/signup",
    component: () => import("@/views/accounts/SignUp.vue")
  },
  {
    name: "gradeup",
    path: "/accounts/gradeup",
    component: () => import("@/views/accounts/GradeUp.vue"),
    beforeEnter: requireAuth()
  },
  {
    name: "signup-certify",
    path: "/accounts/signup/certify",
    component: () => import("@/views/accounts/SignUpCertify.vue")
  },
  {
    name: "signup-certify-notice",
    path: "/accounts/signup/certify/notice",
    component: () => import("@/views/accounts/SignUpCertifyNotice.vue")
  },
  {
    name: "signup-result",
    path: "/accounts/signup/result",
    component: () => import("@/views/accounts/SignUpResult.vue")
  },
  {
    name: "password-reset",
    path: "/accounts/password-reset",
    component: () => import("@/views/accounts/PasswordReset.vue")
  },
  /*
  {
    name: "password-reset-certify",
    path: "/accounts/password-reset/certify",
    component: () => import("@/views/accounts/PasswordResetCertify.vue")
  },
  */
  {
    name: "password-reset-result",
    path: "/accounts/password-reset/form",
    component: () => import("@/views/accounts/PasswordResetForm.vue")
  },
  {
    name: "personal",
    path: "/accounts/personal",
    component: () => import("@/views/accounts/Personal.vue"),
    beforeEnter: requireAuth()
  },
  {
    name: "password-change",
    path: "/accounts/password-change",
    component: () => import("@/views/accounts/PasswordChange.vue"),
    beforeEnter: requireAuth()
  },
  {
    name: "withdrawal",
    path: "/accounts/withdrawal",
    component: () => import("@/views/accounts/Withdrawal.vue"),
    beforeEnter: requireAuth()
  },
  {
    name: "user-inactivate",
    path: "/accounts/inactivate",
    component: () => import("@/views/accounts/Inactivate.vue")
  }
];
