"use strict";

import {THEME_API} from "@/api/theme.js";
import {setTheme} from "@/utils/editor.js";
import store from "@/store";

const theme = {
  namespaced: true,
  state: {
    themeLoadData: {},
    saveHistoryListData: {},
    saveHistoryDeleteData: {},
    userThemeListData: {},
    userThemeUpdateData: {},
    userThemeDeleteData: {},
    userThemeCopyData: {},
    userThemeIntegrateCreate: {},
    themeListData: {},
    themeReadData: {},
    themeExportData: {},
    themeCategoryData: {},
    themeCatalogData: {},
    userThemeExportData: {},
    themeExportHistoryData: {},
    themeTypeFacesData: {},
    themeTypeFacesUpdateData: {},
    savePartnerHistoryListData: {},
    savePartnerHistoryDeleteData: {}
  },
  mutations: {
    setThemeLoad(state, data) {
      state.themeLoadData = data;

      const theme = setTheme(data);

      store.commit(
        "ModuleEditorContainer/setEditablePages",
        data.editablePages
      );
      store.commit(
        "ModuleEditorContainer/setComponentLayouts",
        theme.componentLayout
      );
      store.commit(
        "ModuleEditorContainer/setComponentContainers",
        theme.componentContainer
      );
    },
    setSaveHistoryList(state, data) {
      state.saveHistoryListData = data;
    },
    setSaveHistoryDelete(state, data) {
      state.saveHistoryDeleteData = data;
    },
    setUserThemeList(state, data) {
      state.userThemeListData = data;
    },
    setUserThemeUpdate(state, data) {
      state.userThemeUpdateData = data;
    },
    setUserThemeDelete(state, data) {
      state.userThemeDeleteData = data;
    },
    setUserThemeCopy(state, data) {
      state.userThemeCopyData = data;
    },
    setUserThemeIntegrateCreate(state, data) {
      state.userThemeIntegrateCreate = data;
    },
    setThemeList(state, data) {
      state.themeListData = data;
    },
    setThemeRead(state, data) {
      state.themeReadData = data;
    },
    setThemeExport(state, data) {
      state.themeExportData = data;
    },
    setThemeCategory(state, data) {
      state.themeCategoryData = data;
    },
    setThemeCatalog(state, data) {
      state.themeCatalogData = data;
    },
    setUserThemeExport(state, data) {
      state.userThemeExportData = data;
    },
    setThemeExportHistory(state, data) {
      state.themeExportHistoryData = data;
    },
    setThemeTypeFacesData(state, data) {
      state.themeTypeFacesData = data;
    },
    setThemeTypeFacesUpdateDate(state, data) {
      state.themeTypeFacesUpdateData = data;
    },
    setSavePartnerHistoryList(state, data) {
      state.savePartnerHistoryListData = data;
    },
    setSavePartnerHistoryDelete(state, data) {
      state.savePartnerHistoryDeleteData = data;
    }
  },
  actions: {
    // 테마 통합 저장하기
    actThemeSave({commit}, payload) {
      return THEME_API.themeSave(payload).then(response => {
        commit("setThemeLoad", response.data);
        return response;
      });
    },
    // 테마 통합 불러오기
    actThemeLoad({commit}, payload) {
      return THEME_API.themeLoad(payload).then(response => {
        commit("setThemeLoad", response.data);
        return response;
      });
    },
    // 회원 저장 히스토리 목록
    actSaveHistoryList({commit}, payload) {
      return THEME_API.saveHistoryList(payload).then(response => {
        commit("setSaveHistoryList", response.data);
        return response;
      });
    },
    // 회원 저장 히스토리 삭제
    actSaveHistoryDelete({commit}, payload) {
      return THEME_API.saveHistoryDelete(payload).then(response => {
        commit("setSaveHistoryDelete", response.data);
        return response;
      });
    },
    // 회원 테마 목록
    actUserThemeList({commit}, payload) {
      return THEME_API.userThemeList(payload).then(response => {
        commit("setUserThemeList", response.data);
        return response;
      });
    },
    // 회원 테마 수정
    actUserThemeUpdate({commit}, payload) {
      return THEME_API.userThemeUpdate(payload).then(response => {
        commit("setUserThemeUpdate", response.data);
        return response;
      });
    },
    // 회원 테마 삭제
    actUserThemeDelete({commit}, payload) {
      return THEME_API.userThemeDelete(payload).then(response => {
        commit("setUserThemeDelete", response.data);
        return response;
      });
    },
    // 회원 테마 복사
    actUserThemeCopy({commit}, payload) {
      return THEME_API.userThemeCopy(payload).then(response => {
        commit("setUserThemeCopy", response.data);
        return response;
      });
    },
    // 회원 테마 통합생성하기
    actUserThemeIntegrateCreate({commit}, payload) {
      return THEME_API.userThemeIntegrateCreate(payload).then(response => {
        commit("setUserThemeIntegrateCreate", response.data);
        return response;
      });
    },
    // 테마 목록
    actThemeList({commit}, payload) {
      return THEME_API.themeList(payload).then(response => {
        commit("setThemeList", response.data);
        return response;
      });
    },
    // 테마 상세
    actThemeRead({commit}, payload) {
      return THEME_API.themeRead(payload).then(response => {
        commit("setThemeRead", response.data);
        return response;
      });
    },
    // 테마 내보내기
    actThemeExport({commit}, payload) {
      return THEME_API.themeExport(payload).then(response => {
        commit("setThemeExport", response.data);
        return response;
      });
    },
    // 테마-카테고리 목록
    actThemeCategory({commit}, payload) {
      return THEME_API.themeCategory(payload).then(response => {
        commit("setThemeCategory", response.data);
        return response;
      });
    },
    //테마 목록(카테고리 검색)
    actThemeCatalog({commit}, payload) {
      return THEME_API.themeCatalog(payload).then(response => {
        commit("setThemeCatalog", response.data);
        return response;
      });
    },
    // 회원 테마 내보내기
    actUserThemeExport({commit}, payload) {
      return THEME_API.userThemeExport(payload).then(response => {
        commit("setUserThemeExport", response.data);
        return response;
      });
    },
    // 테마 내보내기 내역
    actThemeExportHistory({commit}, payload) {
      return THEME_API.themeExportHistory(payload).then(response => {
        commit("setThemeExportHistory", response.data);
        return response;
      });
    },
    // 테마 서체 데이터(파트너)
    actThemeTypeFaces({commit}, payload) {
      return THEME_API.themeTypeFaces(payload).then(response => {
        commit("setThemeTypeFacesData", response.data);
        return response;
      });
    },
    // 테마 서체설정(파트너)
    actThemeTypeFacesUpdate({commit}, payload) {
      return THEME_API.themeTypeFacesUpdate(payload).then(response => {
        commit("setThemeTypeFacesUpdateDate", response.data);
        return response;
      });
    },
    // 파트너 저장 히스토리 목록
    actSavePartnerHistoryList({commit}, payload) {
      return THEME_API.savePartnerHistoryList(payload).then(response => {
        commit("setSavePartnerHistoryList", response.data);
        return response;
      });
    },
    // 파트너 저장 히스토리 삭제
    actSavePartnerHistoryDelete({commit}, payload) {
      return THEME_API.savePartnerHistoryDelete(payload).then(response => {
        commit("setSavePartnerHistoryDelete", response.data);
        return response;
      });
    }
  },
  getters: {
    getThemeLoadData(state) {
      return state.themeLoadData;
    },
    getSaveHistoryListData(state) {
      return state.saveHistoryListData;
    },
    getSaveHistoryDeleteData(state) {
      return state.saveHistoryDeleteData;
    },
    getUserThemeListData(state) {
      return state.userThemeListData;
    },
    getUserThemeUpdateData(state) {
      return state.userThemeUpdateData;
    },
    getUserThemeDeleteData(state) {
      return state.userThemeDeleteData;
    },
    getUserThemeCopyData(state) {
      return state.userThemeCopyData;
    },
    getUserThemeIntegrateCreate(state) {
      return state.userThemeIntegrateCreate;
    },
    getThemeListData(state) {
      return state.themeListData;
    },
    getThemeReadData(state) {
      return state.themeReadData;
    },
    getThemeExportData(state) {
      return state.themeExportData;
    },
    getThemeCategoryData(state) {
      return state.themeCategoryData;
    },
    getThemeCatalogData(state) {
      return state.themeCatalogData;
    },
    getUserThemeExportData(state) {
      return state.userThemeExportData;
    },
    getThemeExportHistoryData(state) {
      return state.themeExportHistoryData;
    },
    getThemeTypeFacesData(state) {
      return state.themeTypeFacesData;
    },
    getThemeTypeFacesUpdateData(state) {
      return state.themeTypeFacesUpdateData;
    },
    getSavePartnerHistoryListData(state) {
      return state.savePartnerHistoryListData;
    },
    getSavePartnerHistoryDeleteData(state) {
      return state.savePartnerHistoryDeleteData;
    }
  }
};

export default theme;
