"use strict";

import wrapper from "@/api/wrapper/axios-wrapper.js";
import {getAuthTokenHeader} from "@/utils/auth.js";

export const COMPONENT_API = {
  // 컴포넌트 불러오기
  componentLoad(payload) {
    return wrapper.get(
      `/v1/component/${payload.componentId}/load`,
      {},
      {
        headers: getAuthTokenHeader()
      }
    );
  },
  // 컴포넌트 목록
  componentList(payload) {
    let params = {};
    if (payload.solutionId) {
      params.solutionId = [1];
      params.solutionId.push(payload.solutionId);
    }
    if (payload.sortBy) params.sortBy = payload.sortBy;
    if (payload.page) params.page = payload.page;
    if (payload.perPage) params.perPage = payload.perPage;
    if (payload.firstCategory) params.firstCategory = payload.firstCategory;
    if (payload.secondCategory) params.secondCategory = payload.secondCategory;
    if (payload.startedAt) params.startedAt = payload.startedAt;
    if (payload.userPartnerId) params.userPartnerId = payload.userPartnerId;
    if (payload.status) params.status = payload.status;
    if (payload.display) params.display = payload.display;

    return wrapper.get(`/v1/component`, params, {
      headers: getAuthTokenHeader()
    });
  },
  // 컴포넌트 상세
  componentRead(payload) {
    let params = {};
    if (payload.withOptions) params.withOptions = payload.withOptions;
    if (payload.withRenderData) params.withRenderData = payload.withRenderData;

    return wrapper.get(`/v1/component/${payload.componentId}`, params, {
      headers: getAuthTokenHeader()
    });
  },
  // 테마 컴포넌트 목록
  themeComponentList(payload) {
    return wrapper.get(`/v1/theme/${payload.themeId}/component`, payload, {
      headers: getAuthTokenHeader()
    });
  }
};

export default COMPONENT_API;
