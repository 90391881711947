"use strict";

import wrapper from "@/api/wrapper/axios-wrapper.js";

export const BOARD_API = {
  // 게시판 글 목록
  boardList(payload) {
    return wrapper.get(`/v1/board/${payload.boardId}/post`);
  },
  // 게시판 공지사항 목록
  boardNoticeList() {
    const params = {
      sortBy: "+sort,id-"
    };
    return wrapper.get(`/v1/board/3/post`, params);
  },
  // 게시판 사용팁 목록
  boardUsetipList() {
    const params = {
      sortBy: "+sort,id-"
    };
    return wrapper.get(`/v1/board/2/post`, params);
  },
  // 게시판 faq 목록
  boardFaqList() {
    const params = {
      sortBy: "+sort,id-"
    };
    return wrapper.get(`/v1/board/4/post`, params);
  },
  // 게시글 페이지수 계산
  boardFindPage(payload) {
    const params = {
      perPage: 20,
      sortBy: "+sort,-id"
    };

    return wrapper.get(
      `/v1/board/${payload.boardId}/post/${payload.postId}/find-out-page`,
      params
    );
  }
};

export default BOARD_API;
