"use strict";

const introCommon = {
  namespaced: true,
  state: {
    temp: null,
    floating: {
      showState: false,
      position: {
        top: 0 + "px",
        left: 0 + "px"
      },
      data: [],
      type: "userInfo"
    },
    expanded: {
      showState: false,
      data: []
    },
    fullDim: {
      showState: false,
      data: null
    },
    redirectPage: null,
    currentMenuInfo: {},
    menuList: [
      {
        id: 100,
        name: "큐픽기능",
        children: [
          {
            id: 101,
            name: "쉬운편집",
            icon: "function1",
            clickType: "push",
            clickTarget: "/introduction/function1"
          },
          {
            id: 102,
            name: "반응형 빌더",
            icon: "function2",
            clickType: "push",
            clickTarget: "/introduction/function2"
          },
          {
            id: 103,
            name: "반응형 테마",
            icon: "function3",
            clickType: "push",
            clickTarget: "/introduction/function3"
          },
          {
            id: 104,
            name: "컴포넌트",
            icon: "function4",
            clickType: "push",
            clickTarget: "/introduction/function4"
          },
          {
            id: 105,
            name: "쇼핑몰 솔루션",
            icon: "function5",
            clickType: "push",
            clickTarget: "/introduction/function5"
          },
          {
            id: 106,
            name: "스페셜 기능",
            icon: "function6",
            clickType: "push",
            clickTarget: "/introduction/function6"
          }
        ]
      },
      {
        id: 200,
        name: "테마",
        // path: "/introduction/theme"
        path: "/introduction/theme-list?id=3"
      },
      // {id: 300, name: "컴포넌트", path: "/introduction/component"},
      {
        id: 400,
        name: "헬프센터",
        children: [
          {
            id: 401,
            name: "시작하기",
            icon: "help1",
            clickType: "window",
            clickTarget:
              process.env.VUE_APP_EXTERNAL_qpick_help_URL + "/gallery?id=1"
          },
          {
            id: 402,
            name: "사용팁",
            icon: "help2",
            clickType: "window",
            clickTarget:
              process.env.VUE_APP_EXTERNAL_qpick_help_URL + "/board?id=2"
          },
          // {
          //   id: 403,
          //   name: "테마소식",
          //   icon: "help3"
          // },
          // {
          //   id: 404,
          //   name: "컴포넌트 소식",
          //   icon: "help4"
          // },
          {
            id: 405,
            name: "공지사항",
            icon: "help5",
            clickType: "window",
            clickTarget:
              process.env.VUE_APP_EXTERNAL_qpick_help_URL + "/board?id=3"
          },
          {
            id: 406,
            name: "FAQ",
            icon: "help6",
            clickType: "window",
            clickTarget:
              process.env.VUE_APP_EXTERNAL_qpick_help_URL + "/board?id=4"
          },
          {
            id: 407,
            name: "1:1 문의",
            icon: "help7",
            clickType: "window",
            clickTarget:
              process.env.VUE_APP_EXTERNAL_qpick_help_URL + "/inquiry"
          }
        ]
      }
    ]
  },
  mutations: {
    setTemp(state, data) {
      state.temp = data;
    },
    setFloatingShowState(state, data) {
      state.floating.showState = data;
    },
    setFloatingData(state, data) {
      state.floating.data = data;
    },
    setFloatingPosition(state, data) {
      state.floating.position = data;
    },
    setFloatingType(state, data) {
      state.floating.type = data;
    },
    setFullDimShowState(state, data) {
      state.fullDim.showState = data;
    },
    setFullDimData(state, data) {
      state.fullDim.data = data;
    },
    setRedirectPage(state, data) {
      state.redirectPage = data;
    },
    setExpandedShowState(state, data) {
      state.expanded.showState = data;
    },
    setExpandedData(state, data) {
      state.expanded.data = data;
    },
    setCurrentMenuInfo(state, data) {
      state.currentMenuInfo = data;
    }
  },
  getters: {
    getTemp(state) {
      return state.temp;
    },
    getFloatingShowState(state) {
      return state.floating.showState;
    },
    getFloatingData(state) {
      return state.floating.data;
    },
    getFloatingPosition(state) {
      return state.floating.position;
    },
    getFloatingType(state) {
      return state.floating.type;
    },
    getFullDimShowState(state) {
      return state.fullDim.showState;
    },
    getFullDimData(state) {
      return state.fullDim.data;
    },
    getRedirectPage(state) {
      return state.redirectPage;
    },
    getExpandedShowState(state) {
      return state.expanded.showState;
    },
    getExpandedData(state) {
      return state.expanded.data;
    },
    getCurrentMenuInfo(state) {
      return state.currentMenuInfo;
    },
    getMenuList(state) {
      return state.menuList;
    }
  }
};

export default introCommon;
