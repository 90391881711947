"use strict";

import {AUTH_API} from "@/api/component-option.js";

const componentOption = {
  namespaced: true,
  state: {
    componentOptionListData: {},
    componentOptionReadData: {},
    componentOptionUpdateData: {},
    partnerComponentOptionListData: {},
    partnerPropertyUpdateData: {}
  },
  mutations: {
    setComponentOptionList(state, data) {
      state.componentOptionListData = data;
    },
    setComponentOptionRead(state, data) {
      state.componentOptionReadData = data;
    },
    setComponentOptionUpdate(state, data) {
      state.componentOptionUpdateData = data;
    },
    setPartnerComponentOptionList(state, data) {
      state.partnerComponentOptionListData = data;
    },
    setPartnerPropertyUpdateData(state, data) {
      state.partnerPropertyUpdateData = data;
    }
  },
  actions: {
    // 컴포넌트 옵션 목록
    actComponentOptionList({commit}, payload) {
      return AUTH_API.componentOptionList(payload).then(response => {
        commit("setComponentOptionList", response.data);
        return response;
      });
    },
    // 컴포넌트 옵션 상세
    actComponentOptionRead({commit}, payload) {
      return AUTH_API.componentOptionRead(payload).then(response => {
        commit("setComponentOptionRead", response.data);
        return response;
      });
    },
    // 컴포넌트 옵션 수정
    actComponentOptionUpdate({commit}, payload) {
      return AUTH_API.componentOptionUpdate(payload).then(response => {
        commit("setComponentOptionUpdate", response.data);
        return response;
      });
    },
    // 컴포넌트 옵션 목록(파트너)
    actPartnerComponentOptionList({commit}, payload) {
      return AUTH_API.partnerComponentOptionList(payload).then(response => {
        commit("setPartnerComponentOptionList", response.data);
        return response;
      });
    },
    // 컴포넌트 옵션 프로퍼티 기본값 수정(파트너)
    actPartnerPropertyUpdate({commit}, payload) {
      return AUTH_API.partnerPropertyUpdate(payload).then(response => {
        commit("setPartnerPropertyUpdateData", response.data);
        return response;
      });
    }
  },
  getters: {
    getComponentOptionListData(state) {
      return state.componentOptionListData;
    },
    getComponentOptionReadData(state) {
      return state.componentOptionReadData;
    },
    getComponentOptionUpdateData(state) {
      return state.componentOptionUpdateData;
    },
    getPartnerComponentOptionListData(state) {
      return state.partnerComponentOptionListData;
    },
    getPartnerPropertyUpdateData(state) {
      return state.partnerPropertyUpdateData;
    }
  }
};

export default componentOption;
