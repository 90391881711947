"use strict";

import {USER_API} from "@/api/accounts/user.js";

const user = {
  namespaced: true,
  state: {
    userLogData: {},
    userProgressStepData: {},
    userProgressStepShow: false
  },
  mutations: {
    setUserLog(state, data) {
      state.userLogData = data;
    },
    setUserProgressStep(state, data) {
      state.userProgressStepData = data;
    },
    setUserProgressStepShow(state, data) {
      state.userProgressStepShow = data;
    }
  },
  actions: {
    actUserLog({commit}, payload) {
      return USER_API.userLog(payload).then(response => {
        commit("setUserLog", response.data);
        return response;
      });
    },
    actUserProgressStep({commit}, payload) {
      return USER_API.userProgressStep(payload).then(response => {
        commit("setUserProgressStep", response.data);
        return response;
      });
    }
  },
  getters: {
    getUserLogData(state) {
      return state.userLogData;
    },
    getUserProgressStepData(state) {
      return state.userProgressStepData;
    },
    getUserProgressStepShow(state) {
      return state.userProgressStepShow;
    }
  }
};

export default user;
