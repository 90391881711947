var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "tooltip",
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.popupShow.apply(null, arguments);
      }
    }
  }, [_c('div', {
    ref: "content",
    staticClass: "tooltip__default-icon tooltip__content",
    style: "color: ".concat(_vm.color)
  }, [_c('i', {
    staticClass: "xi-help-o"
  })]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isShow,
      expression: "isShow"
    }, {
      name: "click-outside",
      rawName: "v-click-outside:popupHide",
      arg: "popupHide"
    }],
    staticClass: "tooltip__box",
    style: "top: ".concat(_vm.top, ";left: ").concat(_vm.left)
  }, [_c('div', {
    staticClass: "tooltip__message"
  }, [_vm.title ? _c('p', {
    staticClass: "tooltip__message-title",
    domProps: {
      "innerHTML": _vm._s(_vm.title)
    }
  }) : _vm._e(), _vm.text ? _c('p', {
    staticClass: "tooltip__message-text",
    domProps: {
      "innerHTML": _vm._s(_vm.text)
    }
  }) : _vm._e(), _vm._t("default"), _c('i', {
    staticClass: "xi-close tooltip__message-close",
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.popupHide.apply(null, arguments);
      }
    }
  })], 2)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }