import Vue from "vue";
import App from "@/App.vue";
import {router} from "@/router/index.js";
import store from "@/store/index.js";
import "@/assets/scss/reset.scss";
import "@/assets/scss/style.scss";
import "@/assets/scss/xeicon.scss";
import GlobalComponent from "@/plugins/globalComponent";
import filter from "@/utils/filter";
import floatingVue from "floating-vue";
import "floating-vue/dist/style.css";

Vue.config.productionTip = false;

// EventBus
Vue.prototype.$EventBus = new Vue();

Vue.use(GlobalComponent);
Vue.use(filter);
Vue.use(floatingVue, {
  themes: {
    "black-dropdown": {
      $extend: "dropdown",
      $resetCss: true
    }
  }
});

// Directive
Vue.directive("click-outside", {
  bind: (el, binding, vnode) => {
    el.eventClickOutside = event => {
      if (!(el == event.target || el.contains(event.target))) {
        vnode?.context[binding.arg](binding.value);
      }
    };
    document.body.addEventListener("click", el.eventClickOutside);
  },
  unbind: el => {
    document.body.removeEventListener("click", el.eventClickOutside);
  }
});

new Vue({
  render: h => h(App),
  router,
  store
}).$mount("#app");
