<template>
  <div class="frmThumb">
    <div class="frmThumb__loader" v-show="attach.loading">
      <i class="xi-spinner-1 xi-spin"></i>
    </div>
    <div class="frmThumb__attach" v-if="attach.url">
      <button type="button">이미지 삭제</button>
      <img :src="attach.url" @click="pickImage" />
    </div>
    <button type="button" class="frmThumb__select" @click="pickImage" v-else>
      이미지 선택
    </button>
  </div>
</template>

<script>
import {createNamespacedHelpers} from "vuex";

const ModuleEditor = createNamespacedHelpers("ModuleEditor");
const ModuleAttach = createNamespacedHelpers("ModuleAttach");

export default {
  name: "ImagePicker",
  props: {
    index: {
      type: Number
    },
    value: {
      type: String
    },
    option: {
      type: Object
    }
  },
  data() {
    return {
      attach: {
        url: null,
        loading: false
      }
    };
  },
  methods: {
    // 이미지 선택으로 이동
    pickImage() {
      this.setPopup({
        page: "ComponentImage",
        index: this.index,
        value: this.value,
        option: this.option
      });
    },
    // 컴포넌트 이미지 상세
    componentImageRead(id) {
      let payload = {};
      payload.id = id.substr(1);

      this.actComponentImageRead(payload).then(response => {
        this.attach.loading = false;
        this.attach.url = response.data?.url;
      });
    },
    // 파트너 이미지 상세
    partnerImageRead(id) {
      let payload = {};
      payload.id = id.substr(1);

      this.actPartnerImageRead(payload).then(response => {
        this.attach.loading = false;
        this.attach.url = response.data?.url;
      });
    },
    // 이미지 상세
    imageRead(id) {
      this.attach.loading = true;

      if (id.substr(0, 1) === "p") this.partnerImageRead(id);
      if (id.substr(0, 1) === "c") this.componentImageRead(id);
    },

    ...ModuleEditor.mapMutations(["setPopup"]),
    ...ModuleAttach.mapActions(["actComponentImageRead", "actPartnerImageRead"])
  },
  mounted() {
    if (this.value && this.value !== "") {
      this.imageRead(this.value);
    }
  }
};
</script>

<style lang="scss" scoped>
.frmThumb {
  position: relative;
  width: 100%;
  min-height: 200px;
  background-color: #f7f9fc;
  cursor: pointer;

  &__loader {
    z-index: 1;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px dashed #acaeb2;
    background-color: #f7f9fc;

    i {
      color: #309574;
      font-size: 30px;
    }
  }
  &__select {
    width: 100%;
    height: 200px;
    padding-top: 50px;
    border: 1px dashed #acaeb2;
    background-image: url($path + "ico_pick.svg");
    background-repeat: no-repeat;
    background-position: 50% calc(50% - 15px);
    color: #acaeb2;
  }
  &__attach {
    position: relative;
    height: 280px;
    border: 1px solid #dcdcdc;

    button {
      display: none;
      position: absolute;
      top: 16px;
      right: 16px;
      width: 24px;
      height: 24px;
      background-image: url($path + "ico_close.svg");
      background-repeat: no-repeat;
      background-position: 0 0;
      text-indent: -9999px;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: scale-down;
    }
  }
}
</style>
