export default [
  {
    name: "mysoho-interlock-entrance",
    path: "/mysoho/interlock",
    component: () => import("@/views/external/mysoho/InterlockEntrance.vue")
  },
  {
    name: "mysoho-interlock-register",
    path: "/mysoho/register",
    component: () => import("@/views/external/mysoho/InterlockRegister.vue")
  },
  {
    name: "mysoho-interlock-complete",
    path: "/mysoho/complete",
    component: () => import("@/views/external/mysoho/InterlockComplete.vue")
  },
  {
    name: "mysoho-interlock-link",
    path: "/mysoho/link",
    component: () => import("@/views/external/mysoho/InterlockLink.vue")
  }
];
