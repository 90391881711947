import Vue from "vue";
import Vuex from "vuex";
import ModuleAdmin from "@/store/modules/admin.js";
import ModuleAuth from "@/store/modules/auth.js";
import ModuleUser from "@/store/modules/user.js";
import ModuleAlert from "@/store/modules/alert.js";
import ModuleAttach from "@/store/modules/attach.js";
import ModuleConfirm from "@/store/modules/confirm.js";
import ModuleEditor from "@/store/modules/editor.js";
import ModuleEditorContainer from "@/store/modules/editor-container.js";
import ModuleThemeProduct from "@/store/modules/theme-product.js";
import ModuleTheme from "@/store/modules/theme.js";
import ModuleEditablePage from "@/store/modules/editable-page.js";
import ModuleLinkedComponent from "@/store/modules/linked-component.js";
import ModuleComponentOption from "@/store/modules/component-option.js";
import ModuleComponent from "@/store/modules/component.js";
import ModuleUserSite from "@/store/modules/user-site.js";
import ModuleSpinner from "@/store/modules/spinner.js";
import ModuleBoard from "@/store/modules/board.js";
import ModuleIntroCommon from "@/store/modules/intro-common.js";
import ModuleAlarm from "@/store/modules/alarm.js";

import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    ModuleAdmin,
    ModuleAuth,
    ModuleUser,
    ModuleAlert,
    ModuleAttach,
    ModuleConfirm,
    ModuleEditor,
    ModuleEditorContainer,
    ModuleThemeProduct,
    ModuleTheme,
    ModuleEditablePage,
    ModuleLinkedComponent,
    ModuleComponentOption,
    ModuleComponent,
    ModuleUserSite,
    ModuleSpinner,
    ModuleBoard,
    ModuleIntroCommon,
    ModuleAlarm
  },
  plugins: [
    createPersistedState({
      key: "qpick-admin",
      storage: window.localStorage,
      paths: ["ModuleAuth", "ModuleIntroCommon"]
    })
  ]
});
