"use strict";

import {ALARM_API} from "@/api/alarm.js";

const alarm = {
  namespaced: true,
  state: {
    alarmListData: {},
    alarmCheckUpdateData: {},
    alarmCheckAllUpdateData: {}
  },
  mutations: {
    setAlarmList(state, data) {
      state.alarmListData = data;
    },
    setAlarmCheckUpdate(state, data) {
      state.alarmCheckUpdateData = data;
    },
    setAlarmCheckAllUpdate(state, data) {
      state.alarmCheckAllUpdateData = data;
    }
  },
  actions: {
    // 알림 목록
    actAlarmList({commit}, payload) {
      return ALARM_API.alarmList(payload).then(response => {
        commit("setAlarmList", response.data);
        return response;
      });
    },
    // 알림 확인
    actAlarmCheckUpdate({commit}, payload) {
      return ALARM_API.alarmCheckUpdate(payload).then(response => {
        commit("setAlarmCheckUpdate", response.data);
        return response;
      });
    },
    // 알림 전체 확인
    actAlarmCheckAllUpdate({commit}, payload) {
      return ALARM_API.alarmCheckAllUpdate(payload).then(response => {
        commit("setAlarmCheckAllUpdate", response.data);
        return response;
      });
    }
  },
  getters: {
    getAlarmListData(state) {
      return state.alarmListData;
    },
    getAlarmCheckUpdateData(state) {
      return state.alarmCheckUpdateData;
    },
    getAlarmCheckAllUpdateData(state) {
      return state.alarmCheckAllUpdateData;
    }
  }
};

export default alarm;
